import React, { useEffect, useRef, useState } from "react";
import "../../src/App.css";
import { makeApiRequest } from "../axiosService/apiCall";
import axios from "axios";
import $ from "jquery";
import moment from "moment"

function Adminearn() {

    const [selledBuys, setSelledBuys] = useState([]);
    const [selledBulks, setSelledBulks] = useState([]);
    const [currentBuyPage, setCurrentBuyPage] = useState(1);
    const [currentBulkPage, setCurrentBulkPage] = useState(1);
    const [TotalAmountSelledBuy, setTotalAmountSelledBuy] = useState(0);
    const [TotalAmountSelledBulk, setTotalAmountSelledBulk] = useState(0);
    const [activeTab, setActiveTab] = useState('BUY');
    const [sliceSelledBuys, setsliceSelledBuys] = useState(0);
    const [sliceSelledBulks, setsliceSelledBulks] = useState(0);
    const [buyList, setBuyList] = useState([])
    const [bulkList, setBulkList] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryBulk, setSearchQueryBulk] = useState("")
    const [currentBuyAdminPage, setCurrentBuyAdminPage] = useState(1);
    const [currentBulkAdminPage, setCurrentBulkAdminPage] = useState(1);

    const itemsPerPage = 5;

    useEffect(() => {
        if (selledBuys.length > 0) {
            const startIndexBuy = (currentBuyPage - 1) * itemsPerPage;
            const endIndexBuy = startIndexBuy + itemsPerPage;
            const sliceSelledBuys = selledBuys.slice(startIndexBuy, endIndexBuy);
            const totalAmountPageBuy = sliceSelledBuys.reduce((acc, order) => acc + order.usdtPrice, 0);
            setsliceSelledBuys((totalAmountPageBuy).toFixed(2));
        }
    }, [currentBuyPage, selledBuys]);

    useEffect(() => {
        if (selledBulks.length > 0) {
            const startIndexBulk = (currentBulkPage - 1) * itemsPerPage;
            const endIndexBulk = startIndexBulk + itemsPerPage;
            const sliceSelledBulks = selledBulks.slice(startIndexBulk, endIndexBulk);
            const totalAmountPageBulk = sliceSelledBulks.reduce((acc, order) => acc + order.usdtPrice, 0);
            setsliceSelledBulks((totalAmountPageBulk).toFixed(2));
        }
    }, [currentBulkPage, selledBulks]);

    useEffect(() => {
        AdminEarn();
    }, []);

    const AdminEarn = async () => {
        try {
            let params = {
                url: `adminEarn`,
                method: "GET",
            };
            const response = await makeApiRequest(params);
            // console.log("AdminEarn--in -", response);
            if (response.status == true) {
                const selledBuy = response.myFractionOrders;
                const selledBulk = response.myBulkOrders;
                const totalAmountSelledBuy = selledBuy.reduce((acc, order) => acc + order.usdtPrice, 0);
                const totalAmountselledBulk = selledBulk.reduce((acc, order) => acc + order.usdtPrice, 0);
                setSelledBuys(selledBuy);
                setSelledBulks(selledBulk);
                setTotalAmountSelledBuy((totalAmountSelledBuy).toFixed(2));
                setTotalAmountSelledBulk((totalAmountselledBulk).toFixed(2));
            }else{
                setSelledBuys([]);
                setSelledBulks([]);
                setTotalAmountSelledBuy(0);
                setTotalAmountSelledBulk(0);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const buyAdminSelled = () => {
        const totalBuyPages = Math.ceil(selledBuys.length / itemsPerPage);
        return renderPaginationNumbers(totalBuyPages, setCurrentBuyAdminPage)
    };

    const buyAdminSearch = () => {
        const totalBuyAdminPages = Math.ceil(buyadmin.length / itemsPerPage);
        return renderPaginationNumbers(totalBuyAdminPages, currentBuyAdminPage);
    };

    const bulkAdminSelled = () => {
        const totalBulkPages = Math.ceil(selledBulks.length / itemsPerPage);
        return renderPaginationNumbers(totalBulkPages, setCurrentBulkAdminPage)
    };

    const bulkAdminSearch = () => {
        const totalBulkAdminPages = Math.ceil(bulkadmin.length / itemsPerPage);
        return renderPaginationNumbers(totalBulkAdminPages, setCurrentBulkAdminPage, currentBulkAdminPage);
    };


    const renderPaginationNumbers = () => {
        const paginationNumbers = [];
        if (activeTab === 'BUY') {
            const totalPages = Math.ceil(selledBuys.length / itemsPerPage);

            const totalBuyAdminPages = Math.ceil(buyadmin.length / itemsPerPage);
            const maxVisiblePages = 5;

            let totalToShow = 0;
            if (totalBuyAdminPages == 0) {
                totalToShow = Math.min(totalPages, maxVisiblePages);
            } else if (totalBuyAdminPages > 0) {
                totalToShow = Math.min(totalPages, totalBuyAdminPages, maxVisiblePages);
            }

            if (totalToShow <= maxVisiblePages) {
                for (let i = 1; i <= totalToShow; i++) {
                    paginationNumbers.push(i);
                }
            } else {
                const leftBoundary = Math.min(
                    Math.max(1, currentBuyPage - Math.floor(maxVisiblePages / 2)),
                    totalPages - maxVisiblePages + 1
                );
                const rightBoundary = Math.max(
                    Math.min(totalPages, currentBuyPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );
                const buyLeftBoundary = Math.min(
                    Math.max(1, currentBuyAdminPage - Math.floor(maxVisiblePages / 2)),
                    totalBuyAdminPages - maxVisiblePages + 1
                );
                const buyRightBoundary = Math.max(
                    Math.min(totalBuyAdminPages, currentBuyAdminPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );

                const currentPage = selledBuys.length ? currentBuyPage : currentBuyAdminPage;

                if (currentPage < Math.floor(maxVisiblePages / 2) + 1) {
                    for (let i = 1; i <= maxVisiblePages; i++) {
                        paginationNumbers.push(i);
                    }
                } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
                    for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
                        paginationNumbers.push(i);
                    }
                } else {
                    for (let i = selledBuys.length ? leftBoundary : buyLeftBoundary;
                        i <= (selledBuys.length ? rightBoundary : buyRightBoundary);
                        i++) {
                        paginationNumbers.push(i);
                    }
                }
            }

            return paginationNumbers.map((pageNumber, index) => (
                <li
                    key={index}
                    className={`page-item ${(currentBuyPage === pageNumber || currentBuyAdminPage === pageNumber) ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => {
                            setCurrentBuyPage(pageNumber);
                            setCurrentBuyAdminPage(pageNumber);
                        }}
                    >
                        {pageNumber}
                    </button>
                </li>
            ));
        } else {
            const totalPages = Math.ceil(selledBulks.length / itemsPerPage);
            const totalBulkAdminPages = Math.ceil(bulkadmin.length / itemsPerPage);

            const maxVisiblePages = 5;

            let totalToShow = 0;
            if (totalBulkAdminPages == 0) {
                totalToShow = Math.min(totalPages, maxVisiblePages);
            } else if (totalBulkAdminPages > 0) {
                totalToShow = Math.min(totalPages, totalBulkAdminPages, maxVisiblePages);
            }

            if (totalToShow <= maxVisiblePages) {
                for (let i = 1; i <= totalToShow; i++) {
                    paginationNumbers.push(i);
                }
            } else {
                const leftBoundary = Math.min(
                    Math.max(1, currentBulkPage - Math.floor(maxVisiblePages / 2)),
                    totalPages - maxVisiblePages + 1
                );
                const rightBoundary = Math.max(
                    Math.min(totalPages, currentBulkPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );
                const buyLeftBoundary = Math.min(
                    Math.max(1, currentBulkAdminPage - Math.floor(maxVisiblePages / 2)),
                    totalBulkAdminPages - maxVisiblePages + 1
                );
                const buyRightBoundary = Math.max(
                    Math.min(totalBulkAdminPages, currentBulkAdminPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );

                const currentPage = selledBulks.length ? currentBulkPage : currentBulkAdminPage;

                if (currentPage < Math.floor(maxVisiblePages / 2) + 1) {
                    for (let i = 1; i <= maxVisiblePages; i++) {
                        paginationNumbers.push(i);
                    }
                } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
                    for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
                        paginationNumbers.push(i);
                    }
                } else {
                    for (let i = selledBuys.length ? leftBoundary : buyLeftBoundary;
                        i <= (selledBuys.length ? rightBoundary : buyRightBoundary);
                        i++) {
                        paginationNumbers.push(i);
                    }
                }
            }

            return paginationNumbers.map((pageNumber, index) => (
                <li
                    key={index}
                    className={`page-item ${(currentBulkPage === pageNumber) || (currentBulkAdminPage === pageNumber) ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => { setCurrentBulkPage(pageNumber); setCurrentBulkAdminPage(pageNumber) }}
                    >
                        {pageNumber}
                    </button>
                </li>
            ));
        }

    };

    const handleChange = async () => {
        try {
            let params = {
                url: "adminEarn",
                method: "GET"
            }
            const response = await makeApiRequest(params)
            if(response.status == true){
                const buyOrder = response.myFractionOrders
                const bulkOrder = response.myBulkOrders;
                setBuyList(buyOrder)
                setBulkList(bulkOrder)
            }else{
                setBuyList([])
                setBulkList([])
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBuy = async (e) => {
        setSearchQuery(e.target.value)
        handleChange()
    }

    const handleBulk = async (e) => {
        setSearchQueryBulk(e.target.value)
        handleChange()
    }


    const buyadmin = buyList.filter(data => {
        // console.log("buyadmin", data);
        if (searchQuery && ((data.productId.ampouleno && data.productId.ampouleno.includes(searchQuery)) || (data.userId.email && data.userId.email.includes(searchQuery)))) {
            return data;
        }
        return false;
    });


    const bulkadmin = bulkList.filter(data => {
        // console.log("bulkadmin", data);
        if (searchQueryBulk && ((data.productId.ampouleno && data.productId.ampouleno.includes(searchQueryBulk)) || (data.email && data.email.includes(searchQueryBulk)))) {
            return data;
        }
        return false;
    });


    return (
        <div className='App text-dark'>
            <div className='container-fluid'>
                <div className='custom-addmin-earn'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="py-3">
                                    <h3 className="component-user">Admin Earnings</h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12'>
                                        <div className='custom-earn-inside mt-3'>
                                            <ul class="nav nav-pills mb-3 text-center justify-content-lg-end align-items-center" id="pills-tab" role="tablist" style={{ position: "relative" }}>
                                                <li class="nav-item " role="presentation">
                                                    <button class="nav-link active register-btn-2 me-3 total-1-1" id="pills-buy-tab" data-bs-toggle="pill" data-bs-target="#pills-buy" type="button" role="tab" aria-controls="pills-buy" aria-selected="true" onClick={() => setActiveTab('BUY')}>Fraction</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link register-btn-2   total-1-2" id="pills-bulk-tab" data-bs-toggle="pill" data-bs-target="#pills-bulk" type="button" role="tab" aria-controls="pills-bulk" aria-selected="false" onClick={() => setActiveTab('BULK')}>Bulk</button>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab" tabindex="0">
                                                    <h3 style={{ color: "#BC9018", position: "absolute", top: "100px" }}>Total Fraction USDT Price : <span className="fs-4" style={{ color: "#BC9018" }}>{TotalAmountSelledBuy}</span></h3>
                                                    <div>
                                                        <input
                                                            type="text" z
                                                            placeholder="Search by Ampoule number / Email"
                                                            className="p-2"
                                                            style={{ borderRadius: "10px" }}
                                                            value={searchQuery}
                                                            onChange={(e) => handleBuy(e)}
                                                        />
                                                    </div>
                                                    <div className='custom-datascroll text-center'>
                                                        <table class="table  w-100 mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">S.No</th>
                                                                    <th scope="col">Ampoule number</th>
                                                                    <th scope="col">Date (UTC)</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Quantity</th>
                                                                    <th scope="col">Coin</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">USDT</th>

                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {searchQuery ? (
                                                                    <>
                                                                        {buyadmin.length > 0 ? (buyadmin.slice(
                                                                            (currentBuyAdminPage - 1) * itemsPerPage,
                                                                            currentBuyAdminPage * itemsPerPage
                                                                        )
                                                                            .map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1 + ((currentBuyAdminPage - 1) * itemsPerPage)}</td>
                                                                                    <td>{data.productId.ampouleno}</td>
                                                                                    <td>{`${data.date.split("T")[0]} - ${moment.utc(data.time).format("hh:mm A")}`}</td>
                                                                                    <td>{data.userId?.email || "--"}</td>
                                                                                    <td>{data.quantity}</td>
                                                                                    <td>{data.network}</td>
                                                                                    <td>{Number(data.amount).toFixed(5)}</td>
                                                                                    <td>{data.usdtPrice}</td>
                                                                                </tr>
                                                                            ))) : (
                                                                            <tr>
                                                                                <td colSpan="7" className="text-center">
                                                                                    No Data
                                                                                </td>
                                                                            </tr>
                                                                        )

                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {(selledBuys || []).length > 0 ? (
                                                                            <>
                                                                                {selledBuys
                                                                                    .slice(
                                                                                        (currentBuyPage - 1) * itemsPerPage,
                                                                                        currentBuyPage * itemsPerPage
                                                                                    )
                                                                                    .map((data, index) => (
                                                                                        <tr key={data._id}>
                                                                                            <td>{index + 1 + ((currentBuyPage - 1) * itemsPerPage)}</td>
                                                                                            <td>{data.productId.ampouleno}</td>
                                                                                            <td>{`${data.date.split("T")[0]} - ${moment.utc(data.time).format("hh:mm A")}`}</td>
                                                                                            <td>{data.userId?.email || "--"}</td>
                                                                                            <td>{data.quantity}</td>
                                                                                            <td>{data.network}</td>
                                                                                            <td>{Number(data.amount).toFixed(5)}</td>
                                                                                            <td>{data.usdtPrice}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>Total: {Number(sliceSelledBuys)}</td>
                                                                                </tr>
                                                                            </>
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7" className="text-center">
                                                                                    No Data
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                    <nav>
                                                        <ul className="pagination justify-content-center">
                                                            {searchQuery ? buyAdminSearch() : buyAdminSelled()}
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div class="tab-pane fade" id="pills-bulk" role="tabpanel" aria-labelledby="pills-bulk-tab" tabindex="0">
                                                    <h3 style={{ color: "#BC9018", position: "absolute", top: "100px" }}>Total Bulk USDT Price : <span className="fs-4" style={{ color: "#BC9018" }}>{TotalAmountSelledBulk}</span></h3>
                                                    <div>
                                                        <input
                                                            type="text" z
                                                            placeholder="Search by Ampoule number / Email"
                                                            className="p-2"
                                                            style={{ borderRadius: "10px" }}
                                                            value={searchQueryBulk}
                                                            onChange={(e) => handleBulk(e)}
                                                        />
                                                    </div>
                                                    <div className='custom-datascroll text-center'>
                                                        <table class="table  w-100 mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">S.No</th>
                                                                    <th scope="col">Ampoule number</th>
                                                                    <th scope="col">Date (UTC)</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Quantity</th>
                                                                    <th scope="col">Coin</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">USDT</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {searchQueryBulk ? (
                                                                    <>
                                                                        {bulkadmin.length > 0 ? (bulkadmin
                                                                            .slice(
                                                                                (currentBulkAdminPage - 1) * itemsPerPage,
                                                                                currentBulkAdminPage * itemsPerPage
                                                                            )
                                                                            .map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{data && data.ampouleno ? data.ampouleno : data.productId.ampouleno}</td>
                                                                                    <td>{data && data.date ? `${data.date.split("T")[0]} - ${moment.utc(data.time).format("hh:mm A")}` : 'N/A'}</td>
                                                                                    <td>{data && data.email}</td>
                                                                                    <td>{data && data.quantity || 'N/A'}</td>
                                                                                    <td>{data && data.network || 'N/A'}</td>
                                                                                    <td>{Number(data && data.amount).toFixed(5) || 'N/A'}</td>
                                                                                    <td>{data.usdtPrice}</td>
                                                                                </tr>
                                                                            ))) : (<tr>
                                                                                <td colSpan="7" className="text-center">
                                                                                    No Data
                                                                                </td>
                                                                            </tr>)

                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {(selledBulks || []).length > 0 ? (
                                                                            <>
                                                                                {selledBulks
                                                                                    .slice(
                                                                                        (currentBulkPage - 1) * itemsPerPage,
                                                                                        currentBulkPage * itemsPerPage
                                                                                    )
                                                                                    .map((data, index) => (
                                                                                        <tr key={data._id}>
                                                                                            <td>{index + 1 + ((currentBulkPage - 1) * itemsPerPage)}</td>
                                                                                            <td>{data.productId.ampouleno}</td>
                                                                                            <td>
                                                                                                {data.date.split("T")[0]}-{moment.utc(data.time).format("hh:mm A")}
                                                                                            </td>
                                                                                            <td>{data.email}</td>
                                                                                            <td>{data.quantity}</td>
                                                                                            <td>{data.network}</td>
                                                                                            <td>
                                                                                                {Number(data.amount).toFixed(8)}
                                                                                            </td>
                                                                                            <td>{(data.usdtPrice).toFixed(2)}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>Total: {Number(sliceSelledBulks)}</td>
                                                                                </tr>
                                                                            </>
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7" className="text-center">
                                                                                    No Data
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </>
                                                                )

                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <nav>
                                                        <ul className="pagination justify-content-center">
                                                            {searchQueryBulk ? bulkAdminSearch() : bulkAdminSelled()}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adminearn