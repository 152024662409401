import React, { useEffect, useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { makeApiRequest } from "../axiosService/apiCall";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";
import moment from "moment"
import CryptoJS from "crypto-js";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { IoMdClose } from "react-icons/io";
import config from "./config";
// import { id } from "ethers";

const AddAdmin = () => {
  const [email, setEmail] = useState("");
  const [editEmail, setEditEmail] = useState("")
  const [password, setPassword] = useState("");
  const [adminType, setAdminType] = useState(0);
  const [addAdminError, setAddAdminError] = useState("");
  const [data, setData] = useState([]);
  const [adminAuth, setAdminAuth] = useState(false);
  const [date, setDate] = useState("")
  const [credentials, setCredentials] = useState({
    bulkorder: 0,
    fractionorder: 0,
    kycverify: 0,
    redeemedorder: 0,
    uploadcuptoken: 0
  });
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState("password");
  const [particularAdmin, setParticularAdmin] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  // console.log("email---", email);

  const passwordMatch = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~()\-_+=!@#$%^&*<>`:{}\[\];'?",.])(?=.*\d).{8,}$/;

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.value, "event.target.value");
    setAdminType(event.target.value);
    setAddAdminError((prevErrors) => ({
      ...prevErrors,
      adminType: "",
    }));
  };

  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setCredentials((prevData) => ({
      ...prevData,
      [name]: checked ? 1 : 0
    }));
  }

  async function handleAddSubadmin(event, type) {
    event.preventDefault();
    const validationErrors = {};
    try {
      if (!email) {
        validationErrors.email = "*Email is required";
      } else if (!isValidEmail(email)) {
        validationErrors.email = "*Invalid email format";
      }

      if (!password) {
        validationErrors.password = "*Password is required";
      }else if(!passwordMatch.test(password)){
        validationErrors.password = "*Password must contain at least 8 characters, one uppercase letter, one number, and atleast one symbol!";
      }
      if (adminType == 0) {
        validationErrors.adminType = "*Please select option";
      }
      if (!Object.values(credentials).some(value => value !== 0)) {
        validationErrors.privilege = "*At least one privilege must be selected";
      }

      setAddAdminError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        setAddAdminError(validationErrors);
      } else {
        setIsLoading(true)
        let payLoad = {
          email: email,
          password: password,
          adminType: adminType,
          credentials: credentials,
          date: date
        };

        if (type === "add") {
          let params = {
            url: `add-admin`,
            method: "POST",
            data: { ...payLoad, date: new Date().toISOString() },
          };
          let response = await makeApiRequest(params);
          // console.log("admin---resp", response);
          if (response.status == true) {
            setIsLoading(false)
            toast.success(`${response.message}`);
            setEmail("");
            setPassword("");
            setAdminType(0);
            setDate("")
            setCredentials({ bulkorder: 0, fractionorder: 0, kycverify: 0, uploadcuptoken: 0, redeemedorder: 0 })
            fetchData();
            setTimeout(() => {
              window.location.reload()
            }, 2000);
          } else {
            setIsLoading(false)
            toast.warn(`${response.message}`);
            setEmail("");
            setPassword("");
            setAdminType(0);
            setDate("")
            setCredentials({ bulkorder: 0, fractionorder: 0, kycverify: 0, uploadcuptoken: 0, redeemedorder: 0 })
          }
        } else {
          console.log("user not created");
        }
      }
    } catch (error) {
      console.log("add subadmin error", error);
      toast.warn(`Something went wrong`);
    }
  }


  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const columns = [
    { name: "S.No", width: "60px", selector: (row) => row.sno },

    {
      name: "Email",
      width: "300px",
      selector: (row) => row.email,
      cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
    },
    {
      name: "Privilege",
      width: "300px",
      selector: (row) => row.privilege,
      cell: (row) => (
        <div className="no-wrap-cell" style={{whiteSpace:"break-spaces"}}>
          {row.privilege.map((priv, index) => (
            <span key={index}>
              {priv.bulkorder === 1 && <span>Bulk Order</span>}
              {priv.fractionorder === 1 && <span>{priv.bulkorder === 1 ? ',' : ''} Fraction Order</span>}
              {priv.kycverify === 1 && <span>{(priv.bulkorder === 1 || priv.fractionorder === 1) ? ',' : ''} KYC Verify</span>}
              {priv.redeemedorder === 1 && <span>{(priv.bulkorder === 1 || priv.fractionorder === 1 || priv.kycverify === 1) ? ',' : ''} Redeemed Order</span>}
              {priv.uploadcuptoken === 1 && <span>{(priv.bulkorder === 1 || priv.fractionorder === 1 || priv.kycverify === 1 || priv.redeemedorder === 1) ? ',' : ''} Upload CupToken</span>}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Admin Type",
      width: "200px",
      selector: (row) => row.adminType,
      cell: (row) => <div className="no-wrap-cell">{row.adminType}</div>,
    },
    {
      name: "LastLoginIP",
      width: "150px",
      selector: (row) => row.LastLoginIP,
      cell: (row) => <div className=" no-wrap-cell">{row.LastLoginIP}</div>,
    },
    {
      name: "Date",
      width: "150px",
      selector: (row) => row.date,
      cell: (row) => <div className="date">{moment(row.date).format('L')}</div>,
    },
    {
      name: "Status",
      button: true,
      width: "200px",
      cell: (row) => (
        <div className="d-flex">

          <button type="button" class="register-btn-1 me-2 register-btn-copy" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(event) => handleEdit(row.email,event)}>
            Edit
          </button>

          <button className="register-btn-1 me-2 register-btn-copy" onClick={() => handleDelete(row.email)}>Delete</button>
        </div>
      ),
    },
  ];
  // console.log("particularAdmin", particularAdmin);
  const handleEdit = async (email,event) => {
    setAddAdminError("");
    event.preventDefault()
    // console.log("handleEdit--email---", email);
    setEditEmail(email)
    getParticularAdmin(email)
  
  };

  const handleUpdate = async (editEmail) => {
    const validationErrors = {};
    try {
      if (!email) {
        validationErrors.email = "*Email is required";
      } else if (!isValidEmail(email)) {
        validationErrors.email = "*Invalid email format";
      }

      if (!password) {
        validationErrors.password = "*Password is required";
      }else if(!passwordMatch.test(password)){
        validationErrors.password = "*Password must contain at least 8 characters, one uppercase letter, one number, and atleast one symbol!";
      }
      if (adminType == 0) {
        validationErrors.adminType = "*Please select option";
      }
      if (!Object.values(credentials).some(value => value !== 0)) {
        validationErrors.privilege = "*At least one privilege must be selected";
      }

      setAddAdminError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        setAddAdminError(validationErrors);
      } else {
        // console.log("editEmail-setAddAdminError----", editEmail);
        setIsLoading(true)
        let payLoad = {
          email: email,
          password: password,
          adminType: adminType,
          credentials: credentials,
          date: date
        }
        let params = {
          url: `updateParticularAdmin/${editEmail}`,
          method: "POST",
          data: payLoad
        };
        const response = await makeApiRequest(params);
        // console.log("Edit clicked for:--------", response);
        if (response.status) {
          setIsLoading(false)
          toast.success(`${response.message}`);
          setEmail(email);
          setPassword(password);
          setAdminType(adminType);
          setDate(date)
          setCredentials(credentials)
          fetchData();
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        } else {
          setIsLoading(false)
          toast.warn(`${response.message}`);
        }
      }

    } catch (error) {
      console.log(error);
    }

  }

  const handleDelete = async (email) => {
    try {
      const swalResult = await Swal.fire({
        title: "Are you sure you want Delete?",
        icon: "question",
        iconHtml: "?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true
      });
      // console.log("swalResult",swalResult.isConfirmed);
      if (swalResult.isConfirmed) {
        // console.log("email---------", email);
        let params = {
          url: `deleteParticularAdmin/${email}`,
          method: "POST",
        };
        const response = await makeApiRequest(params);
        // console.log("response---", response);
        if (response.status == true) {
          toast.success("Data Deleted Successfully...");
          fetchData();
        } else {
          toast.error("Data cannot be Deleted...");
        }
      }

    } catch (error) {
      console.log(error);
    }
  };


  const fetchData = async () => {
    try {
      let params = {
        url: "get-subadmins",
        method: "GET",
      };
      let resposne = await makeApiRequest(params);
      if (resposne.status == true) {
        setData(resposne.data);
        setAdminAuth(true);
      } else {
        setData([]);
        setAdminAuth(false);
      }
      // console.log("resposne", resposne);
    } catch (error) {
      console.log("fetchData error", error);
    }
  };

  const datadetails =
    data &&
    data.map((row, index) => ({
      sno: index + 1,
      email: row.email,
      privilege: row.privilege,
      adminType: row.adminType == 2 ? "Sub-Admin" : "Financial-Admin",
      LastLoginIP: row.lastLoginIP,
      Date: row.date,
    }));



  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const getParticularAdmin = async (email) => {
    try {
      let params = {
        url: `getParticularAdmin/${email}`,
        method: "GET",
      };
      let response = await makeApiRequest(params);
      // console.log("getParticularAdmin-response.data", response);
      if (response.status == true) {
        let userPassword = response.data.password;
        var bytes = CryptoJS.AES.decrypt(userPassword, config.encdcrKey);
        var originalPassword = bytes.toString(CryptoJS.enc.Utf8);
        setParticularAdmin(response.data);
        setEmail(response.data.email);
        setPassword(originalPassword);
        setAdminType(response.data.adminType);
        setDate(response.data.date)
        setCredentials((prevData) => ({
          ...prevData,
          bulkorder: response.data.privilege[0].bulkorder,
          fractionorder: response.data.privilege[0].fractionorder,
          kycverify: response.data.privilege[0].kycverify,
          uploadcuptoken: response.data.privilege[0].uploadcuptoken,
          redeemedorder: response.data.privilege[0].redeemedorder
        }))
      } else {
        setParticularAdmin([])
        setEmail("");
        setPassword("");
        setAdminType(0);
        setDate("")
        setCredentials({ bulkorder: 0, fractionorder: 0, kycverify: 0, uploadcuptoken: 0, redeemedorder: 0 })
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };


  return (
    <section className="add-subadmin-section ">
      <ToastContainer />
      <div className="container-fluid" >
        <div className="custom-addsubadmin">
          <div className="row">
            <div className="col-lg-12">
              <div className="row justify-content-center ">
                <div className="col-lg-8">
                  <h3 className=" component-user">Add Sub Admin</h3>
                  <div className="custom-addsubadmin-inside">
                    <div class="mb-3 row">
                      <label
                        for="staticEmail"
                        class="col-sm-3 col-form-label "
                      >
                        Email
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          readonly
                          class="form-control-plaintext border px-3"
                          id="formGroupExampleInput"
                          placeholder="aaa@gmail.com"
                          // value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setAddAdminError((prevErrors) => ({
                              ...prevErrors,
                              email: "",
                            }));
                          }}
                        />
                        <span style={{ color: "#FF0000" }}>
                          {" "}
                          {addAdminError.email && <>{addAdminError.email}</>}
                        </span>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label
                        for="inputPassword"
                        class="col-sm-3 col-form-label "
                      >
                        Password
                      </label>
                      <div className="col-sm-9">
                        <div class="input-group mb-0 ">
                          <input
                            type={type}
                            class="form-control add-pass-1"
                            id="exampleInputPassword1"
                            name="password"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            // value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setAddAdminError((prevErrors) => ({
                                ...prevErrors,
                                password: "",
                              }));
                            }}
                          />
                          <span class="input-group-text eye-icon">
                            {/* <FaEyeSlash /> */}
                            <Icon
                              class="absolute mr-10"
                              icon={icon}
                              size={25}
                              onClick={handleToggle}
                            />
                          </span>
                        </div>
                        <span style={{ color: "#FF0000" }}>
                          {" "}
                          {addAdminError.password && (
                            <>{addAdminError.password}</>
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <label
                        for="inputPassword"
                        class="col-sm-3 col-form-label"
                      >
                        Admin Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          class="form-select border"
                          aria-label="Default select example"
                          // value={adminType}
                          onChange={handleChange}
                        >
                          <option selected value="0">
                            Select admin type
                          </option>
                          <option value="2">Subadmin</option>
                          <option value="3">Financial Admin</option>
                        </select>
                        <span style={{ color: "#FF0000" }}>
                          {" "}
                          {addAdminError.adminType && (
                            <>{addAdminError.adminType}</>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label
                        for="inputPassword"
                        class="col-sm-3 col-form-label"
                      >Privilege</label>
                      <span style={{ color: "#FF0000" }}>
                        {" "}
                        {addAdminError.privilege && (
                          <>{addAdminError.privilege}</>
                        )}
                      </span>
                      <div className="col-sm-12 justify-content-center ">
                        <div className="row">
                          <div className="col-lg-6  d-flex align-items-center">
                            <label class="col-lg-7 col-form-label">Bulk Order</label>
                            <input className="sub-check-1 form-check-input" type="checkbox" name="bulkorder" onChange={handleCheckBoxChange} />
                          </div>

                          <div className="col-lg-6  d-flex align-items-center">
                            <label class="col-lg-7 col-form-label">Fraction Order</label>
                            <input className="sub-check-1 form-check-input" type="checkbox" name="fractionorder" onChange={handleCheckBoxChange} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 d-flex align-items-center">
                            <label class="col-lg-7 col-form-label">Kyc Verify</label>
                            <input className="sub-check-1 form-check-input" type="checkbox" name="kycverify" onChange={handleCheckBoxChange} />
                          </div>

                          <div className="col-lg-6 d-flex align-items-center">
                            <label class="col-lg-7 col-form-label">Redeemed Order</label>
                            <input className="sub-check-1 form-check-input" type="checkbox" name="redeemedorder" onChange={handleCheckBoxChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 d-flex align-items-center">
                            <label class="col-lg-7 col-form-label">Upload Cuptoken</label>
                            <input className="sub-check-1 form-check-input" type="checkbox" name="uploadcuptoken" onChange={handleCheckBoxChange} />
                          </div>
                        </div>


                      </div>
                    </div>
                    <div className="text-center">
                      {isLoading ? (
                        <button type="button" className="register-btn-spinner-1"><Spinner animation="border" /></button>
                      ) : (
                        <button
                          className="register-btn-spinner-1"
                          onClick={(event) => handleAddSubadmin(event, "add", "")}
                        >
                          Submit
                        </button>
                      )}

                    </div>
                  </div>
                  {/* </>
                  )} */}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog custom-subadmin-modal modal-dialog-centered">
            <div class="modal-content custom-subadmin-modal-1">
              <div class="modal-header border-0 pb-0">
                <h3 class="modal-title" id="exampleModalLabel">Edit Sub Admin</h3>
                <button type="button" class="close-btn-1" data-bs-dismiss="modal" aria-label="Close"><IoMdClose /></button>
              </div>
              <div class="modal-body">
                <div className="custom-addsubadmin-inside custom-subadmin-modal-2">
                  <div class="mb-3 row">
                    <label
                      for="staticEmail"
                      class="col-sm-3 col-form-label"
                    >
                      Email
                    </label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        readOnly
                        class="form-control-plaintext border px-3"
                        id="formGroupExampleInput"
                        // placeholder="aaa@gmail.com"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setAddAdminError((prevErrors) => ({
                            ...prevErrors,
                            email: "",
                          }));
                        }}
                      />
                      <span style={{ color: "#FF0000" }}>
                        {" "}
                        {addAdminError.email && <>{addAdminError.email}</>}
                      </span>
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <label
                      for="inputPassword"
                      class="col-sm-3 col-form-label"
                    >
                      Password
                    </label>
                    <div className="col-sm-9">
                      <div class="input-group mb-0 ">
                        <input
                          type={type}
                          class="form-control  add-pass-1"
                          id="exampleInputPassword1"
                          name="password"
                          placeholder="Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setAddAdminError((prevErrors) => ({
                              ...prevErrors,
                              password: "",
                            }));
                          }}
                        />
                        <span class="input-group-text eye-icon">
                          {/* <FaEyeSlash /> */}
                          <Icon
                            class="absolute mr-10"
                            icon={icon}
                            size={25}
                            onClick={handleToggle}
                          />
                        </span>
                      </div>
                      <span style={{ color: "#FF0000" }}>
                        {" "}
                        {addAdminError.password && (
                          <>{addAdminError.password}</>
                        )}
                      </span>
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <label
                      for="inputPassword"
                      class="col-sm-3 col-form-label"
                    >
                      Admin Type
                    </label>
                    <div className="col-sm-9">
                      <select
                        class="form-select border"
                        aria-label="Default select example"
                        value={adminType}
                        onChange={handleChange}
                      >
                        <option selected value="0">
                          Select admin type
                        </option>
                        <option value="2">Subadmin</option>
                        <option value="3">Financial Admin</option>
                      </select>
                      <span style={{ color: "#FF0000" }}>
                        {" "}
                        {addAdminError.adminType && (
                          <>{addAdminError.adminType}</>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="text-left align-items-left d-flex">Privilege</label>
                    <span style={{ color: "#FF0000" }}>
                      {" "}
                      {addAdminError.privilege && (
                        <>{addAdminError.privilege}</>
                      )}
                    </span>
                    <div className="col-sm-12 justify-content-center ">
                      <div className="row">
                        <div className="col-lg-6  d-flex align-items-center">
                          <label class="col-lg-8 col-form-label">Bulk Order</label>
                          <input className="sub-check-1 form-check-input" type="checkbox" name="bulkorder" onChange={handleCheckBoxChange} checked={credentials.bulkorder === 1 ? true : false} />

                        </div>

                        <div className="col-lg-6  d-flex align-items-center">
                          <label class="col-lg-8 col-form-label">Fraction Order</label>
                          <input className="sub-check-1 form-check-input" type="checkbox" name="fractionorder" onChange={handleCheckBoxChange} checked={credentials.fractionorder === 1 ? true : false} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6  d-flex align-items-center">
                          <label class="col-lg-8 col-form-label">kyc verify</label>
                          <input className="sub-check-1 form-check-input" type="checkbox" name="kycverify" onChange={handleCheckBoxChange} checked={credentials.kycverify === 1 ? true : false} />
                        </div>

                        <div className="col-lg-6  d-flex align-items-center">
                          <label class="col-lg-8 col-form-label">Redeemed Order</label>
                          <input className="sub-check-1 form-check-input" type="checkbox" name="redeemedorder" onChange={handleCheckBoxChange} checked={credentials.redeemedorder === 1 ? true : false} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6  d-flex align-items-center">
                          <label class="col-lg-8 col-form-label  ">Upload Cuptoken</label>
                          <input className="sub-check-1 form-check-input" type="checkbox" name="uploadcuptoken" onChange={handleCheckBoxChange} checked={credentials.uploadcuptoken === 1 ? true : false} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    {isLoading ? (
                      <button type="button" className="register-btn-spinner-1"><Spinner animation="border" /></button>
                    ) : (
                      <button
                        className="register-btn-spinner-1"
                        onClick={() => handleUpdate(editEmail)}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  <button type="button" class="btn btn-primary">Save changes</button>
</div> */}
            </div>
          </div>
        </div>

        <div className="sub-admins-table mt-4 mb-5 table-1">
          {adminAuth == true ? (
            <>
              <h3 className=" component-user text-center">Sub Admin</h3>
              <DataTable
                className="text-center ms-3"
                columns={columns}
                data={datadetails}
                onRowClicked={(row) => getParticularAdmin(row.email)}
              // pagination
              // paginationServer
              // paginationComponentOptions={paginationOptions}
              // paginationTotalRows={totalData}
              // paginationPerPage={5}
              // onChangePage={(page) => setCurrentPage(page)}
              persistTableHead={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div >
    </section >
  );
};

export default AddAdmin;  