import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../pages/config";
import CryptoJS from "crypto-js";
import ClaraTokenABI from '../ABI/claraToken.json'
import { makeApiRequest } from "../axiosService/apiCall";
import "../../src/App.css";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../assets/Image/logo.png";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import moment from "moment"
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import Web3 from "web3";
import { IoMdClose } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";



function Saledorder() {
  const [selledBuys, setSelledBuys] = useState([]);
  const [selledBulks, setSelledBulks] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [currentBuyPage, setCurrentBuyPage] = useState(1);
  const [currentBulkPage, setCurrentBulkPage] = useState(1);
  const [activeTab, setActiveTab] = useState('BUY');
  const itemsPerPage = 5;
  const [loadingStates, setLoadingStates] = useState({});

  var web3js = '';
  if (window.ethereum) {
    web3js = new Web3(window.ethereum);
  } else {
    web3js = new Web3(new Web3.providers.HttpProvider(config.D_Chain_Rpc_Url));

  }

  const selledItems = async () => {
    try {
      let params = {
        url: `selledItems`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log("response--",response);
      if (response.status == true) {
        const selledBuy = response.aggregatedSelledBuyOrders;
        const selledBulk = response.aggregatedSelledBulkOrders;
        setSelledBuys(selledBuy);
        setSelledBulks(selledBulk);
      } else {
        setSelledBuys([]);
        setSelledBulks([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    selledItems();

    //set Time Interval
    const interval = setInterval(() => {
      selledItems();
    }, 60000);

    return () => clearInterval(interval);
  }, [currentBuyPage, currentBulkPage]);

  const handleStatusChange = async (orderId, newStatus, productId, type) => {
    // console.log("orderId----", orderId, "newStatus---", newStatus);
    try {
      const swalResult = await Swal.fire({
        title: "Are you sure you want to deliver?",
        icon: "question",
        iconHtml: "?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true
      });
      // console.log("swalResult",swalResult.isConfirmed);
      if (swalResult.isConfirmed) {
        let payload = {
          OrderId: orderId,
          Status: newStatus,
          productId: productId,
          type: type,
        };

        let params = {
          url: `/deliveredStatus`,
          method: "POST",
          data: payload,
        };

        const response = await makeApiRequest(params);
        if (response.status == true) {
          selledItems();
        }
      } else {
        const updatedStatus = "Sell";

        setSelectedStatus({
          ...selectedStatus,
          [orderId]: updatedStatus,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(config.D_ChainID).toString(16)}` }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${Number(config.D_ChainID).toString(16)}`,
                chainName: 'D chain Mainnet',
                rpcUrls: [config.D_Chain_Rpc_Url] /* ... */,
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          console.log(addError)
        }
      }
      // handle other "switch" errors
    }
  }


  const handlePushToBurn = async (orderId, productId, type) => {
    try {
      const ChainConfirmation = await Swal.fire({
        title: "Please Switch to D-Chain Network",
        icon: "info",
        iconHtml: "!",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true
      });

      if (ChainConfirmation.isConfirmed) {
        const swalResult = await Swal.fire({
          title: "Are you sure you want to PushToBurn?",
          icon: "question",
          iconHtml: "?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          showCloseButton: true
        });
        if (swalResult.isConfirmed) {
          setLoadingStates(prevStates => ({
            ...prevStates,
            [orderId]: true,
          }));
          if (window.ethereum) {
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });

            localStorage.setItem('address', accounts[0])
            const address = localStorage.getItem('address');

            await switchNetwork()

            // For CupToken code Live
            const CupToken_Contract_address = new web3js.eth.Contract(ClaraTokenABI, config.CUP_Token_Addr);
            const gasPriceWei = web3js.utils.toWei('20', 'gwei');
            const buyAmount = await web3js.utils.toWei('1', 'ether');
            const resultOfTransfer = await CupToken_Contract_address.methods.transfer(config.admin_Address, buyAmount).send({
              from: address,
              gasPrice: gasPriceWei,
            });

            // For ClaraToken code Staging
            // const Clara_Contract_address = new web3js.eth.Contract(ClaraTokenABI, config.Clara_Token_addr);
            // const gasPriceWei = web3js.utils.toWei('20', 'gwei');
            // const buyAmount = await web3js.utils.toWei('1', 'ether');
            // const resultOfTransfer = await Clara_Contract_address.methods.transfer(config.admin_Address, buyAmount).send({
            //   from: address,
            //   gasPrice: gasPriceWei,
            // });
            
            // console.log("resultOfTransfer-0--", resultOfTransfer);
            const TransactionHash = resultOfTransfer.transactionHash
            // console.log("resultOfTransfer-----", TransactionHash);
            if (resultOfTransfer) {
              const randomString = Math.random().toString(36).substring(7);
              // Create a SHA-256 hash
              const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);
              // Prepend "0x" to the hash
              const hashId = "0x" + hash;
              // console.log("hashId---", hashId);
              let payload = {
                OrderId: orderId,
                hashId: hashId,
                productId: productId,
                thash: TransactionHash,
                type: type,
              };

              let params = {
                url: `/pushToBurn`,
                method: "POST",
                data: payload,
              };

              const response = await makeApiRequest(params);
              // console.log("pushToBurn-------",response);
              if (response.status == true) {
                setLoadingStates(false);
                toast.success(`${response.message}`);
                selledItems();
              }
            } else {
              setLoadingStates(false);
              console.log("Failed Transfer");
              toast.warn(`Failed Transfer`);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      toast.warn(`Failed Transfer`);
      setLoadingStates(false);
    }
  };

  const burnAddress = (address) => {
    // Check if the address is defined and is a string
    if (address && typeof address === 'string') {
      let returnData = address.substring(0, 4) + "..." + address.substring(address.length - 4);
      return returnData;
    } else {
      return "...";
    }
  }

  const address = (address) => {
    // console.log(address.substring().length);
    if (address.substring().length > 5) {
      let returnData = address.substring(0, 20) + "..." + address.substring(address.length - 10)
      return returnData
    }
  }

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    if (activeTab == 'BUY') {
      const totalPages = Math.ceil(selledBuys.length / itemsPerPage);

      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        const leftBoundary = Math.min(
          Math.max(1, currentBuyPage - Math.floor(maxVisiblePages / 2)),
          totalPages - maxVisiblePages + 1
        );
        const rightBoundary = Math.max(
          Math.min(totalPages, currentBuyPage + Math.floor(maxVisiblePages / 2)),
          maxVisiblePages
        );

        if (currentBuyPage < Math.floor(maxVisiblePages / 2) + 1) {
          for (let i = 1; i <= maxVisiblePages; i++) {
            paginationNumbers.push(i);
          }
        } else if (currentBuyPage > totalPages - Math.floor(maxVisiblePages / 2)) {
          for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
            paginationNumbers.push(i);
          }
        } else {
          for (let i = leftBoundary; i <= rightBoundary; i++) {
            paginationNumbers.push(i);
          }
        }
      }

      return paginationNumbers.map((pageNumber, index) => (
        <li
          key={index}
          className={`page-item ${currentBuyPage === pageNumber ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentBuyPage(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      ));
    } else {
      const totalPages = Math.ceil(selledBulks.length / itemsPerPage);

      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        const leftBoundary = Math.min(
          Math.max(1, currentBulkPage - Math.floor(maxVisiblePages / 2)),
          totalPages - maxVisiblePages + 1
        );
        const rightBoundary = Math.max(
          Math.min(totalPages, currentBulkPage + Math.floor(maxVisiblePages / 2)),
          maxVisiblePages
        );

        if (currentBulkPage < Math.floor(maxVisiblePages / 2) + 1) {
          for (let i = 1; i <= maxVisiblePages; i++) {
            paginationNumbers.push(i);
          }
        } else if (currentBulkPage > totalPages - Math.floor(maxVisiblePages / 2)) {
          for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
            paginationNumbers.push(i);
          }
        } else {
          for (let i = leftBoundary; i <= rightBoundary; i++) {
            paginationNumbers.push(i);
          }
        }
      }

      return paginationNumbers.map((pageNumber, index) => (
        <li
          key={index}
          className={`page-item ${currentBulkPage === pageNumber ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentBulkPage(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      ));
    }

  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid" style={{ marginLeft: "15px" }}>
        <div className="custom-saled-order text-dark">
          <div className="row">
            <div className="col-sm-12">

              <div className="py-3 justify-content-center d-flex justify-content-between">
                <h3 className=" component-user  text-center ms-lg-0">Redeemed Order</h3>
              </div>
              <div className="custom-inside-saled-order text-center mt-0">
                <ul
                  class="nav nav-pills mb-3 me-lg-0 text-center justify-content-lg-end align-items-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li>

                    <button type="button" class="me-3 info-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                      <CiCircleInfo />
                    </button>


                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header border-0 py-0 m-2 d-flex align-items-center  justify-content-center ">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel" style={{ color: "#bc9018" }}>Remainder</h1>
                            <button type="button" style={{ position: "absolute", right: "10px" }} class="close-btn-1" data-bs-dismiss="modal" aria-label="Close"> <IoMdClose /></button>
                          </div>
                          <div class="modal-body">
                            <div className='submitSpans'>
                              <div className='d-flex'>
                                <p className='text-dark' style={{  textAlign: "end" }}>Burn</p>
                                <div className='ms-0'>
                                  {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                            <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                  <ul style={{ listStyleType: "initial", textAlign: "justify" }}>
                                    <li> Cup token Contract Owner only can send the Redeemed Cup Token to Burn Address.</li>

                                  </ul>
                                </div>

                              </div>
                             
                             


                            </div>
                          </div>
                          {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active register-btn-2 me-4"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => setActiveTab('BUY')}
                    >
                      FRACTION
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link register-btn-2"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={() => setActiveTab('BULK')}
                    >
                      BULK
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="custom-datascroll">
                      <table className="table w-100 mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Ampouleno</th>
                            <th>ProductId</th>
                            <th>Contact Name</th>
                            <th>Company Name</th>
                            <th>Address</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Quantity</th>
                            <th>Date/Time (UTC)</th>
                            <th>Burn Address</th>
                            <th>Status</th>
                            <th>Burn</th>
                            <th>Download address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selledBuys || []).length > 0 ? (
                            selledBuys
                              .slice(
                                (currentBuyPage - 1) * itemsPerPage,
                                currentBuyPage * itemsPerPage
                              )
                              .map((data, index) => (
                                <tr key={data._id}>
                                  <td>{index + 1 + ((currentBuyPage - 1) * itemsPerPage)}</td>
                                  <td>{data.soldItem.ampouleno}</td>
                                  <td>
                                    {data.productId}
                                  </td>
                                  <td>{data.contactname}</td>
                                  <td>{data.companyname ? data.companyname : "--"}</td>
                                  <td>{address(data.address) || data.address}</td>
                                  <td>{data.mobilenumber}</td>
                                  <td>{data.email}</td>
                                  <td>{data.quantity}</td>
                                  <td>
                                    {data.redeemDate.split("T")[0]} - {moment.utc(data.redeemTime).format("hh:mm A")}

                                  </td>
                                  <td>
                                    {data.soldItem.transactionHash !== "" ? (
                                      <a
                                        href={`${config.dchain_explorer_url}/tx/${data.soldItem.transactionHash}`}
                                        target="_blank"
                                        style={{ color: "#bc9018" }}
                                      >
                                        {burnAddress(data.soldItem.transactionHash)}
                                      </a>
                                    ) : (
                                      "..."
                                    )}
                                  </td>

                                  <td className="mt-0">
                                    {data.soldItem.pushToBurn ===
                                      "Completed" ? (
                                      <button
                                        className={`custom-select-btn-5 ${data.soldItem.pushToBurn ===
                                          "Completed"
                                          ? "fade-disabled"
                                          : ""
                                          }`}
                                        disabled={true} // Disable the button
                                      >
                                        Delivered
                                      </button>
                                    ) : (
                                      <select
                                        value={
                                          selectedStatus[data._id] ||
                                          data.status
                                        }
                                        onChange={(e) => {
                                          const newStatus = e.target.value;
                                          setSelectedStatus({
                                            ...selectedStatus,
                                            [data._id]: newStatus,
                                          });

                                          // Call the function to update the status in the database
                                          handleStatusChange(
                                            data._id,
                                            newStatus,
                                            data.productId,
                                            data.soldItem.productType
                                          );
                                        }}
                                        className="custom-select-btn-5"
                                        disabled={data.status === "InProcess"}
                                      >
                                        <option value="Reedem">Reedem</option>
                                        <option value="InProcess"
                                        >Delivered</option>
                                      </select>
                                    )}
                                  </td>
                                  <td>
                                    <div className="custom-pushbtn">

                                      {data.status === "InProcess" ||
                                        data.status === "Completed" ? (
                                        data.soldItem.pushToBurn ===
                                          "Completed" ? (
                                          <button
                                            // className="register-btn-5"
                                            className={`register-btn-5 ${data.soldItem.pushToBurn ===
                                              "Completed"
                                              ? "fade-disabled"
                                              : ""
                                              }`}
                                            disabled={true} // Disable the button
                                          >
                                            {data.soldItem.pushToBurn == "Completed" ? "Burned" : null}
                                          </button>
                                        ) : (
                                          <>
                                            {loadingStates[data._id] ? (
                                              <a ><Spinner animation="border" /></a>
                                            ) : (
                                              <button
                                                onClick={() =>
                                                  handlePushToBurn(
                                                    data._id,
                                                    data.productId,
                                                    data.soldItem.productType
                                                  )
                                                }
                                                className="register-btn-5"
                                              >
                                                {data.soldItem.pushToBurn}
                                              </button>
                                            )}
                                          </>
                                        )
                                      ) : null}

                                    </div>
                                  </td>
                                  <td>
                                    <PDFDownloadLink
                                      style={{ color: "#bc9018" }}
                                      document={
                                        <AddressData addressData={data} />
                                      }
                                      fileName={`address-data-${data.contactname}.pdf`}
                                    >
                                      {({ blob, url, loading, error }) =>
                                        loading
                                          ? "Loading document..."
                                          : "Download PDF"
                                      }
                                    </PDFDownloadLink>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                No Orders
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {renderPaginationNumbers()}
                      </ul>
                    </nav>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <div className="custom-datascroll">
                      <table class="table w-100 mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Ampouleno</th>
                            <th>ProductId</th>
                            <th>Contact Name</th>
                            <th>Company Name</th>
                            <th>Address</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Quantity</th>
                            <th>Date/Time (UTC)</th>
                            <th>Burn Address</th>
                            <th>Status</th>
                            <th>Burn</th>
                            <th>DownLoad PDF</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selledBulks || []).length > 0 ? (
                            selledBulks
                              .slice(
                                (currentBulkPage - 1) * itemsPerPage,
                                currentBulkPage * itemsPerPage
                              )
                              .map((data, index) => (
                                <tr key={data._id}>
                                  <td>{index + 1 + ((currentBulkPage - 1) * itemsPerPage)}</td>
                                  <td>{data.soldItem.ampouleno}</td>
                                  <td>{data.productId}</td>
                                  <td>{data.contactname}</td>
                                  <td>{data.companyname ? data.companyname : "--"}</td>
                                  <td>{address(data.address) || data.address}</td>
                                  <td>{data.mobilenumber}</td>
                                  <td>{data.email}</td>
                                  <td>{data.quantity}</td>
                                  <td>
                                    {data.redeemDate.split("T")[0]} - {moment.utc(data.redeemTime).format("hh:mm A")}
                                  </td>
                                  <td>
                                    {data.soldItem.transactionHash !== "" ? (
                                      <a
                                        href={`${config.dchain_explorer_url}/tx/${data.soldItem.transactionHash}`}
                                        target="_blank"
                                        style={{ color: "#bc9018" }}
                                      >
                                        {burnAddress(data.soldItem.transactionHash)}
                                      </a>
                                    ) : (
                                      "..."
                                    )}
                                  </td>

                                  <td className="mt-0">
                                    {data.soldItem.pushToBurn ===
                                      "Completed" ? (
                                      <button
                                        className={`custom-select-btn-5 ${data.soldItem.pushToBurn ===
                                          "Completed"
                                          ? "fade-disabled"
                                          : ""
                                          }`}
                                        disabled={true} // Disable the button
                                      >
                                        Delivered
                                      </button>
                                    ) : (
                                      <select
                                        value={
                                          selectedStatus[data._id] ||
                                          data.status
                                        }
                                        onChange={(e) => {
                                          const newStatus = e.target.value;
                                          setSelectedStatus({
                                            ...selectedStatus,
                                            [data._id]: newStatus,
                                          });

                                          // Call the function to update the status in the database
                                          handleStatusChange(
                                            data._id,
                                            newStatus,
                                            data.productId,
                                            data.soldItem.productType
                                          );
                                        }}
                                        className="custom-select-btn-5"
                                        disabled={data.status === "InProcess"}
                                      >
                                        <option value="Reedem">Reedem</option>
                                        <option value="InProcess">
                                          Delivered
                                        </option>
                                      </select>
                                    )}
                                  </td>

                                  <td>
                                    <div className="custom-pushbtn">

                                      {data.status === "InProcess" ||
                                        data.status === "Completed" ? (
                                        data.soldItem.pushToBurn ===
                                          "Completed" ? (
                                          <button
                                            className={`register-btn-5 ${data.soldItem.pushToBurn ===
                                              "Completed"
                                              ? "fade-disabled"
                                              : ""
                                              }`}
                                            disabled={true} // Disable the button
                                          >
                                            {data.soldItem.pushToBurn == "Completed" ? "Burned" : null}
                                          </button>
                                        ) : (
                                          <>
                                            {loadingStates[data._id] ? (
                                              <a><Spinner animation="border" /></a>
                                            ) : (
                                              <button

                                                onClick={() =>
                                                  handlePushToBurn(
                                                    data._id,
                                                    data.productId,
                                                    data.soldItem.productType
                                                  )
                                                }
                                                className="register-btn-5"
                                              >
                                                {data.soldItem.pushToBurn}
                                              </button>
                                            )}
                                          </>
                                        )
                                      ) : null}
                                    </div>
                                  </td>
                                  <td>
                                    <PDFDownloadLink
                                      style={{ color: "#bc9018" }}
                                      document={
                                        <AddressData addressData={data} />
                                      }
                                      fileName={`address-data-${data.contactname}.pdf`}
                                    >
                                      {({ blob, url, loading, error }) =>
                                        loading
                                          ? "Loading document..."
                                          : "Download PDF"
                                      }
                                    </PDFDownloadLink>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="9" className="text-center">
                                No Orders
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {renderPaginationNumbers()}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >


  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 10,
  },
  title: {
    fontSize: 25,
    marginBottom: 10,
    fontWeight: "bold",
  },
  text: {
    fontSize: 14,
    marginBottom: 8,
  },
  footer: {
    textAlign: "center",
    fontSize: 14,
    marginTop: 10,
  },
  logo: {
    maxWidth: 100,
    marginBottom: 10,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const AddressData = ({ addressData }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image style={styles.logo} src={logo}></Image>
          <Text style={styles.title}>User Address</Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Ampouleno :</Text> {addressData.soldItem.ampouleno}
          </Text>
          <Text style={styles.text}><Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Order ID:</Text>  {addressData.productId}</Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Contact Name:</Text> {addressData.contactname}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Company Name:</Text> {addressData.companyname}

          </Text>
          <Text>
            <Text style={styles.text}><Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Address:</Text> {addressData.address}</Text>
          </Text>

          <Text style={styles.text}>
            <Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Mobile No:</Text> {addressData.mobilenumber}
          </Text>
          <Text style={styles.text}><Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Email:</Text> {addressData.email}</Text>
          <Text style={styles.text}><Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Quantity:</Text> {addressData.quantity}</Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: 'heavy', fontSize: '16px' }}>Date/Time (UTC): </Text> {addressData.redeemDate.split("T")[0]} - {moment.utc(addressData.redeemTime).format("hh:mm A")}
          </Text>
          <Text style={{ color: 'black', marginTop: '10px', }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>PinCode :</Text> {addressData.pincode}</Text>
          <Text style={{ color: 'black', marginTop: '10px', }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Country :</Text> {addressData.countryname}</Text>
          <Text style={styles.footer}>
            Copyright © 2024 DICTYS. All Rights Reserved.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Saledorder;