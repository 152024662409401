import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/sidebarLayout.css";
import { BiSolidDashboard } from "react-icons/bi";
import { TbListDetails, TbLogout } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { FaKey } from "react-icons/fa";
import { useLocation } from "react-router-dom";
// import User from '../pages/users'
import BuyOrder from "./Buy.js";
import BulkOrder from "./bulk.js";
import { Logout } from "@mui/icons-material";
import Dashboard from "./Dashboard.js";
// import LogOut from '../pages/logout'
import ImportFile from "./import.js";
import AddAdmin from "../pages/AddSubadmin";
import Saledorder from "../pages/Saledorder.js";
import KycStatus from "../pages/KycStatus.js";
import Adminearn from "./Adminearn.js";
import { GrClose } from "react-icons/gr";
import render1 from "../assets/Image/RENDER-1- copy.webp";
import $ from "jquery";
import { makeApiRequest } from "../axiosService/apiCall.js";
import Logouts from "./logout.js";
import Error from "./Error.js";
import { AiOutlineSecurityScan } from "react-icons/ai";
import Security from "./security.js";
import Sitesetting from "./Sitesetting.js";
import Withdrawstatus from "./Withdrawstatus.js";
import Walletbalance from "./Walletbalance.js";
import UserData from "./UserData.js"

function SidebarLayout() {
  const location = useLocation();
  const [admin, setAdmin] = useState({});
  // console.log("admin---", admin);
  useEffect(() => {
    const getAdmin = async () => {
      let params = {
        url: "getLoginAdmin",
        method: "GET",
      };
      try {
        let response = await makeApiRequest(params);
        setAdmin(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getAdmin();
  }, []);

  $(document).ready(function () {
    if ($(window).width() < 600) {
      // if width is less than 600px
      $(".nav-link").click(function () {
        $("#mySidenav").css("width", "0px");
      });
    } else {
      // $('#v-pills-asset-tab').click(function () {
      //   location.reload();
      // });
    }

    $("#mobile-three-line-collapse").click(function () {
      $("#mySidenav").css("width", "250px");
    });
    $("#mobile-close-collapse").click(function () {
      $("#mySidenav").css("width", "0px");
    });
    $(".dashboard-profile-table-tabs-section-hide").hide();
    $(".details-button-navigation button").click(function () {
      $(".dashboard-profile-section-hide").hide();
      $(".dashboard-profile-table-tabs-section-hide").show();
      $("#mySidenav").css("width", "100px");
      $(".dashboard-main-section").css("margin-left", "100px");
      $(".dashboard-navbar-brand img").css("width", "50px");
      $(".dashboard-navbar-brand img").css("height", "50px");
      $("#mobile-close-arrow-left").hide();
      $(".dashboard-text-1").hide();
      $("#mobile-close-arrow-right").show();
    });
    $(".button-dashboard-table-back").click(function () {
      $(".dashboard-profile-section-hide").show();
      $(".dashboard-profile-table-tabs-section-hide").hide();
      $("#mySidenav").css("width", "250px");
      $(".dashboard-main-section").css("margin-left", "250px");
      $(".dashboard-navbar-brand img").css("width", "80px");
      $(".dashboard-navbar-brand img").css("height", "80px");
      $("#mobile-close-arrow-left").show();
      $(".dashboard-text-1").show();
      $("#mobile-close-arrow-right").hide();
    });

    $("#mobile-close-arrow-right").hide();
    $("#mobile-close-arrow-left").click(function () {
      $("#mySidenav").css("width", "100px");
      $(".dashboard-main-section").css("margin-left", "100px");
      $(".dashboard-navbar-brand img").css("width", "50px");
      $(".dashboard-navbar-brand img").css("height", "50px");
      $("#mobile-close-arrow-left").hide();
      $(".dashboard-text-1").hide();
      $("#mobile-close-arrow-right").show();
    });
    $("#mobile-close-arrow-right").click(function () {
      $("#mySidenav").css("width", "250px");
      $(".dashboard-main-section").css("margin-left", "250px");
      $(".dashboard-navbar-brand img").css("width", "80px");
      $(".dashboard-navbar-brand img").css("height", "80px");
      $("#mobile-close-arrow-left").show();
      $(".dashboard-text-1").show();
      $("#mobile-close-arrow-right").hide();
    });

    if ($("#mySidenav").css("width") === "100px") {
      $("#mySidenav").css("a");
    }
  });

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };

  const renderContent = () => {
    if (location.pathname === "/dashboard") {
      return <Dashboard />;
    }
    if (location.pathname === "/userData") {
      return <UserData />;
    }
    if (
      location.pathname === "/bulkOrder" &&
      admin.privilege[0].bulkorder === 1
    ) {
      return <BulkOrder />;
    }

    if (
      location.pathname === "/fractionOrder" &&
      admin.privilege[0].fractionorder === 1
    ) {
      return <BuyOrder />;
    }
    if (
      location.pathname === "/import" &&
      admin.privilege[0].uploadcuptoken === 1
    ) {
      return <ImportFile />;
    }
    if (location.pathname === "/add-subadmin" && admin.adminType === 1) {
      return <AddAdmin />;
    }
    if (location.pathname === '/redeemed-order' && admin.privilege[0].redeemedorder === 1) {
      return <Saledorder />;
    }
    if (
      location.pathname === "/kyc-status" &&
      admin.privilege[0].kycverify === 1
    ) {
      return <KycStatus />;
    }
    if (location.pathname === "/security") {
      return <Security />;
    }
    if (location.pathname === "/site-setting" && admin.adminType === 1) {
      return <Sitesetting />;
    }
    if (location.pathname === '/admin-earn' && admin.privilege[0].kycverify === 1) {
      return <Adminearn />;
    }
    if (location.pathname === "/withdraw-status") {
      return <Withdrawstatus />;
    }
    if (location.pathname === "/Wallet-balance") {
      return <Walletbalance />;
    }
    return <Error />;
  };

  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
    // console.log("clicked");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <div>
          <span id="mobile-three-line-collapse">&#9776;</span>
          <div className="dashboard-sidenav-section" id="mySidenav">
            <nav
              id="sidebar"
              className="col-md-3 col-lg-12 d-md-block sidebar component-navbar"
            >
              <div className="d-flex flex-row">
                <div className="ms-auto me-3">
                  <span id="mobile-close-collapse">
                    <GrClose />
                  </span>
                </div>
              </div>
              <div className="text-center component-heading">
                <img className="mb-3 render-img" src={render1}></img>
              </div>
              <div className="position-sticky">
                {admin && !isObjectEmpty(admin) && (
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a
                        className={`nav-link component-tabs ${location.pathname === "/dashboard" ? "active" : ""
                          }`}
                        href="/dashboard"
                      >
                        <TbListDetails /> Dashboard
                      </a>
                    </li>

                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/userData"
                              ? "active"
                              : ""
                            }`}
                          href="/userData"
                        >
                          <AiOutlineSecurityScan /> User Data
                        </a>
                      </li>
                    )}


                    {admin.privilege[0].bulkorder === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link component-tabs ${location.pathname === "/bulkOrder" ? "active" : ""
                            }`}
                          href="/bulkOrder"
                        >
                          <TbListDetails /> Bulk Order History
                        </a>
                      </li>
                    )}

                    {admin.privilege[0].fractionorder === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link component-tabs ${location.pathname === "/fractionOrder"
                              ? "active"
                              : ""
                            }`}
                          href="/fractionOrder"
                        >
                          <TbListDetails /> Fraction Order History
                        </a>
                      </li>
                    )}

                    {admin.privilege[0].uploadcuptoken === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/import" ? "active" : ""
                            }`}
                          href="/import"
                        >
                          <TbListDetails /> Upload CupToken
                        </a>
                      </li>
                    )}

                    {admin.privilege[0].redeemedorder === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/redeemed-order" ? "active" : ""
                            }`}
                          href="/redeemed-order"
                        >
                          <TbListDetails /> Redeemed Order
                        </a>
                      </li>
                    )}
                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/withdraw-status"
                              ? "active"
                              : ""
                            }`}
                          href="/withdraw-status"
                        >
                          <AiOutlineSecurityScan /> Withdraw Status
                        </a>
                      </li>
                    )}
                    {admin.privilege[0].kycverify === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/kyc-status" ? "active" : ""
                            }`}
                          href="/kyc-status"
                        >
                          <TbListDetails /> KYC Status
                        </a>
                      </li>
                    )}

                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link component-tabs ${location.pathname === "/add-subadmin"
                              ? "active"
                              : ""
                            }`}
                          href="/add-subadmin"
                        >
                          <TbListDetails /> Add Subadmin
                        </a>
                      </li>
                    )}
                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link component-tabs ${location.pathname === "/admin-earn" ? "active" : ""
                            }`}
                          href="/admin-earn"
                        >
                          <TbListDetails /> Admin Earn
                        </a>
                      </li>
                    )}



                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/Wallet-balance" ? "active" : ""
                            }`}
                          href="/Wallet-balance"
                        >
                          <AiOutlineSecurityScan /> Wallet Balance
                        </a>
                      </li>
                    )}

                    <li className="nav-item">
                      <a
                        className={`nav-link ${location.pathname === "/security" ? "active" : ""
                          }`}
                        href="/security"
                      >
                        <AiOutlineSecurityScan /> Security
                      </a>
                    </li>



                    {/* {admin.kycverify === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/" ? "active" : ""
                            }`}
                          onClick={logOut}
                        >
                          <TbLogout /> Logout
                        </a>
                      </li>
                    )} */}


                    {admin.adminType === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${location.pathname === "/site-setting"
                              ? "active"
                              : ""
                            }`}
                          href="/site-setting"
                        >
                          <AiOutlineSecurityScan /> Site Settings
                        </a>
                      </li>
                    )}


                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <a
                        className={`nav-link ${location.pathname === "/" ? "active" : ""
                          }`}
                        onClick={logOut}
                      >
                        <TbLogout /> Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </nav>
          </div>
        </div>

        {/* Main Content ===> 1ST CHOICE */}

        {/* <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          {admin && !isObjectEmpty(admin) && (
            <>
              {location.pathname === "/bulkOrder" ? (admin.bulkorder === 1 ? <BulkOrder /> : <Error />) : null}
              {location.pathname === "/fractionOrder" ? (admin.buyorder === 1 ? <BuyOrder /> : <Error />) : null}
              {location.pathname === "/import" ? (admin.uploadcuptoken === 1 ? <ImportFile /> : <Error />) : null}
              {location.pathname === "/add-subadmin" ? (admin.adminType === 1 ? <AddAdmin /> : <Error />) : null}
              {location.pathname === "/redeemed-order" ? (admin.saledorder === 1 ? <Saledorder /> : <Error />) : null}
              {location.pathname === "/kyc-status" ? (admin.kycverify === 1 ? <KycStatus /> : <Error />) : null}
            </>
          )}
        </main> */}

        {/* Main Content ===> 2ND CHOICE */}
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          {admin && !isObjectEmpty(admin) && renderContent()}
        </main>
      </div>
    </div>
  );
}
export default SidebarLayout;
