import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import speakeasy from 'speakeasy'
import base32 from 'hi-base32';
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { makeApiRequest } from "../axiosService/apiCall";
import { setIn } from "formik";
import { Link, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import '../css/style.css';
import render12 from '../assets/Image/RENDER-1- copy.webp';


function Authenticator() {
    const [otp, setOtp] = useState('');

    const location = useLocation()
    const password = location.state && location.state.password;

    // console.log(password, 'googlepage');

    const [userSceret, setUserSecret] = useState()

    const navigate = useNavigate()

    const email = localStorage.getItem('email')

    useEffect(() => {
        get()
    }, [])

    const get = async () => {
        try {
            let params = {
                url: `enable?UserEmail=${email}`,
                method: "GET",
            }
            const response = await makeApiRequest(params);
            if(response.status == true){
                const secret = response.user.secret
                setUserSecret(secret)
            }else{
                setUserSecret("")
            }
        } catch (error) {
            console.log(error);
        }
    }

    const verify = async () => {
        try {
            if (!otp) {
                toast.error("Please enter 2FA code")
            }
            const email = localStorage.getItem('email')
            // console.log(email, 'localstorage email');
            const payload = {
                otp,
                email,
                password
            };
    
            // console.log(payload, 'payload-');
    
            const searchQuery = 'authenticator'
    
            let params = {
                url: `login?query=${searchQuery}`,
                method: "POST",
                data: payload
            }
            // console.log("params", params);
    
            const res = await makeApiRequest(params);
    
            if (res.message == 'Authentication success!') {
                localStorage.setItem('token', res.token)
                toast.success('Authentication Success')
                setTimeout(() => {
                    navigate('/dashboard', { replace: true });
                    window.onpopstate = function (event) {
                        navigate('/dashboard', { replace: true });
                    }
                    window.location.reload()
                }, 3000);
            }
            else {
                toast.error('Authentication failed,Please enter the valid 2FA code')
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
                console.log('error');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <ToastContainer />
            <section className="register-bg-styling-css">

                <div className="container ">
                    <div className="row justify-content-center align-items-center m-0">
                        <div className="col-lg-12 ">
                            <div className="pt-4">
                                <a class="navbar-brand" href="/"><img src={render12}></img></a>
                            </div>
                        </div>
                    </div>
                    <div className="row min-vh-100 text-center align-items-center justify-content-center">

                        <div className="col-lg-4 login-inputs-css">
                            <div className="row align-items-center  text-center  authenticator-card-bg-styling">
                                <div>
                                    <p className="fs-20 fw-500 text-white">Authenticator</p>
                                    <div className="userInput">

                                        <OtpInput
                                            value={otp}
                                            onChange={(value) => {
                                                const numericValue = value.replace(/\D/g, ''); // Remove non-digits
                                                setOtp(numericValue);
                                            }}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>

                                    <button className="custom-aut-btn mt-5" onClick={verify}><AiOutlineArrowRight /></button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );

}

export default Authenticator;
