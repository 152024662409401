import React, { useEffect, useState, useRef } from 'react'
import '../css/Authenticator.css';
import { BsApple } from 'react-icons/bs';
import { BiLogoPlayStore } from 'react-icons/bi';
import { PiCopySimpleFill } from 'react-icons/pi';
import QRCode from "react-qr-code";
import { Link, useLocation } from 'react-router-dom';
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
// import config from '../config/config'
import axios from 'axios'
import speakeasy from 'speakeasy'
import base32 from 'hi-base32';
import { makeApiRequest } from "../axiosService/apiCall";
import { Tooltip } from 'react-tooltip';
import playstore from '../assets/Image/play-store.png';
import applestore from '../assets/Image/App-Store.png';
import config from './config';
import CryptoJS from 'crypto-js';

function Googleauthenticator() {
    const location = useLocation()

    const [SecretKey, setSecretKey] = useState("")
    const [secret, setSceret] = useState()
    const [enable, setEnable] = useState(false)
    const [qrcode, setQrcode] = useState()
    const [verificationCode, setVerificationCode] = useState('');
    const [users, setUsers] = useState({});
    const [key, setKey] = useState()
    const enableRef = useRef();
    const [copied, setCopied] = useState(false);

    const copyText = () => {
        navigator.clipboard.writeText(secret)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Unable to copy secret to clipboard:', err);
            });
    };

    const UserEmail = localStorage.getItem('email')

    const verify = async () => {
        const userEmail = UserEmail;
        const userToken = verificationCode;
        const secret = users[userEmail];

        if (!secret) {
            toast.error('User not Found');
            return;
        }
        else if (!verificationCode) {
            toast.error('Please enter 2FA code')
        }
        else {
            try {
                const secretAscii = base32.decode(secret);
                const secretHex = toHex(secretAscii);

                function toHex(str) {
                    let result = '';
                    for (let i = 0; i < str.length; i++) {
                        result += str.charCodeAt(i).toString(16);
                    }
                    return result;
                }

                const verified = speakeasy.totp.verify({
                    secret: secretHex,
                    encoding: 'hex',
                    token: userToken,
                });


                if (verified) {
                    const payload = {
                        secret: secret,
                        UserEmail
                    }
                    let params = {
                        url: '/secret',
                        method: "POST",
                        data: payload
                    }
                    // const response = await axios.post(`${config.backendurl}/secret`, { secret: secret, UserEmail });
                    const response = await makeApiRequest(params);

                    if (response.status == true) {
                        toast.success('Your 2FA is Enabled');
                        setInterval(() => {
                            window.location.reload()
                        }, 2000);
                    } else {
                        toast.error("Enable Failed");
                    }
                } else {
                    toast.error('Please enter the valid 2FA code');
                }
            } catch (error) {
                console.error('Error during verification:', error.message);
            }
        }

    }
    const disable = async () => {

        if (!verificationCode) {
            toast.error('Please enter 2FA code')
        }
        else {
            try {
                const secret = key
                const secretAscii = base32.decode(secret);
                const secretHex = toHex(secretAscii);
                const userToken = verificationCode;

                function toHex(str) {
                    let result = '';
                    for (let i = 0; i < str.length; i++) {
                        result += str.charCodeAt(i).toString(16);
                    }
                    return result;
                }

                const verified = speakeasy.totp.verify({
                    secret: secretHex,
                    encoding: 'hex',
                    token: userToken,
                });


                if (verified) {
                    const payload = {
                        UserEmail
                    }
                    let params = {
                        url: '/disable',
                        method: "POST",
                        data: payload
                    }
                    const response = await makeApiRequest(params);
                    if (response.status == true) {
                        toast.success('Your 2FA is disabled')
                        setInterval(() => {
                            window.location.reload()
                        }, 2000);
                    }
                }
                else {
                    toast.error("Please enter the valid 2FA code ")
                }
            } catch (error) {
                console.error('Error during verification:', error.message);
            }
        }

    }

    const enabled = async () => {
        let params = {
            url: `enable?UserEmail=${UserEmail}`,
            method: "GET",
            // data:payload
        }

        // const response = await axios.get(`${config.backendurl}/enable?UserEmail=${UserEmail}`);
        const response = await makeApiRequest(params);
        console.log(response,'response security');
        if (response.user.secret) {
            let usersecretKey = response.user.secret
            var bytes = CryptoJS.AES.decrypt(usersecretKey, config.encdcrKey);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            setKey(originalText)
            setEnable(true)
        }
        else {
            setEnable(false)
        }
    }

    const redirectToAppStore = () => {

        window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank');
    };

    const redirectToPlayStore = () => {

        window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2', '_blank');
    };

    useEffect(() => {
        enabled()

        const secret = speakeasy.generateSecret();
        const userEmail = UserEmail;
        setUsers({ ...users, [userEmail]: secret.base32 });
        users[userEmail] = secret.base32;
        const secretKey = users[userEmail]
        setSceret(secretKey)


        if (secretKey) {
            setSecretKey(secretKey.substring(0, 5) +
                "..." +
                secretKey.substring(secretKey.length - 4));
        }

        const otpauth_url = `otpauth://totp/Dictys:${userEmail}?secret=${secret.base32}&issuer=&algorithm=SHA1&digits=6&period=30`;
        setQrcode(otpauth_url);


    }, [UserEmail]);

    return (
        <div className='App text-dark'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-google-authenticator'>
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-inside-google-authenticator'>
                                    <h2>Google-Authenticator</h2>
                                    <p>Download and install the authenticator app</p>
                                </div>
                            </div>
                        </div>
                        <div className=' '>
                            <div className='row align-items-center justify-content-center'>
                                <div className='col-lg-10'>
                                    <div className='custom-store-section '>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='custom-play-store'>
                                                    {/* <BsApple className='custom-play-store-icon' onClick={redirectToAppStore}></BsApple> */}
                                                    <img style={{ width: "10%", cursor: "pointer" }} src={applestore} onClick={redirectToAppStore} ></img>
                                                    <p className='mt-3 mb-1'>Download From</p>
                                                    <h4>App Store</h4>

                                                </div>
                                            </div>
                                            <div className='col-lg-6 h-100'>
                                                <div className='custom-play-store h-100'>
                                                    {/* <BiLogoPlayStore className='custom-play-store-icon' onClick={redirectToPlayStore}></BiLogoPlayStore> */}
                                                    <img style={{ width: "10%", cursor: "pointer" }} src={playstore} onClick={redirectToPlayStore}></img>
                                                    <p className='mt-3 mb-1'>Download From</p>
                                                    <h4>Play Store</h4>
                                                    {/* <div class="col-sm-12">
                                                        <span></span>
                                                        <input type="text"
                                                            name="number"
                                                            class="form-control me-auto py-2 custom-value-color mt-5"
                                                            id="inputPassword"
                                                            placeholder="Enter 2FA Code"
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);

                                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                                if (!/^[0-9\b]+$/.test(keyValue)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => setVerificationCode(e.target.value)}
                                                        />
                                                    </div>
                                                    {
                                                        enable == false ? (
                                                            <button className='mt-4' onClick={verify}>enable</button>
                                                        ) : (
                                                            <button className='mt-4' onClick={disable}>disable</button>
                                                        )
                                                    } */}
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className='custom-play-store'>
                                                        {
                                                            enable == false ? (
                                                                <div className='custom-scan'>
                                                                    <p>Scan this QR in the Authenticator app</p>
                                                                    {qrcode && (
                                                                        <QRCode
                                                                            size={256}
                                                                            style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                                                                            value={qrcode}
                                                                            viewBox={`0 0 256 256`}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }

                                                        <br /><br />
                                                        {
                                                            enable == false ? (
                                                                <div className='custom-scan'>
                                                                    {/* {copied && <p className='copy'>Secret copied!</p>} */}
                                                                    <button onClick={copyText}>{SecretKey}<a data-tooltip-id="my-tooltip-click"><PiCopySimpleFill className='ms-3' /></a></button>
                                                                    <Tooltip
                                                                        id="my-tooltip-click"
                                                                        content="Copied!"
                                                                        events={['click']}
                                                                    />

                                                                    <p className='mt-4'><span className='text-dark'>Note:</span>  Save this Key or you may lose access to your account.</p>
                                                                </div>
                                                            ) : (
                                                                <p className='mt-4'>Your 2FA is enabled right now.</p>
                                                            )
                                                        }
                                                        <div className='row justify-content-center'>
                                                            <div class="col-sm-6">
                                                                <div>
                                                                    <span></span>
                                                                    <input type="text"
                                                                        name="number"
                                                                        class="form-control me-auto py-2 custom-value-color mt-4 security-input"
                                                                        id="inputPassword"
                                                                        placeholder="Enter 2FA Code"
                                                                        onKeyPress={(event) => {
                                                                            const keyCode = event.which || event.keyCode;
                                                                            const keyValue = String.fromCharCode(keyCode);

                                                                            // Allow only numeric values (0-9) and the backspace key (8)
                                                                            if (!/^[0-9\b]+$/.test(keyValue)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                                    />
                                                                </div>
                                                                {
                                                                    enable == false ? (
                                                                        <button className='mt-4' onClick={verify}>enable</button>
                                                                    ) : (
                                                                        <button className='mt-4' onClick={disable}>disable</button>
                                                                    )
                                                                }
                                                            </div>


                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Googleauthenticator