
let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3000",
    backendurl: "http://localhost:5000/",
    adminBackendUrl: "http://localhost:5000/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    bitcoin_explorer_url: 'https://live.blockcypher.com/btc-testnet',
    D_Chain_Rpc_Url: 'https://mainnetcoin.d-ecosystem.io/',
    BNB_Chain_Rpc_Url: "https://bsc-testnet.drpc.org",
    ETH_Chain_Rpc_Url: "https://sepolia.infura.io/v3/7f10d5dfcc934cb7a0df05c340352163",
    CUP_Token_Addr: "0xF1337bf4DB0cAc498C63B0091BC796fE8bA278eF",
    Clara_Token_addr: "0xD10168ad1b3E706be6628b995B53d44182609C58",
    admin_Address: "0x558403aF09539c459e0988f5Aa0Ec86432FEF396",
    busdt_token: "0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
    dusd_token: "0xD10168ad1b3E706be6628b995B53d44182609C58",
    D_ChainID: "5272",
    BNB_ChainID: "97",
    ETH_ChainID: "11155111",
    D_ChainName: "D Chain Mainnet",
    BNB_ChainName: "BNB Smart Chain",
    ETH_ChainName: "Ethereum",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
} else if (environment == "demo") {
  config = {
    frontendurl: "https://staging.adminapanel.cuptoken.trade",
    backendurl: "https://staging.api.cuptoken.trade/",
    adminBackendUrl: "https://staging.api.cuptoken.trade/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    bitcoin_explorer_url: 'https://live.blockcypher.com/btc-testnet',
    D_Chain_Rpc_Url: 'https://mainnetcoin.d-ecosystem.io/',
    BNB_Chain_Rpc_Url: "https://bsc-testnet.drpc.org",
    ETH_Chain_Rpc_Url: "https://sepolia.infura.io/v3/7f10d5dfcc934cb7a0df05c340352163",
    CUP_Token_Addr: "0xF1337bf4DB0cAc498C63B0091BC796fE8bA278eF",
    Clara_Token_addr: "0xD10168ad1b3E706be6628b995B53d44182609C58",
    admin_Address: "0xc7d39B020F27e2cD11A3a945F6ed3F1A8dAd9629",
    busdt_token: "0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
    dusd_token: "0xD10168ad1b3E706be6628b995B53d44182609C58",
    D_ChainID: "5272",
    BNB_ChainID: "97",
    ETH_ChainID: "11155111",
    D_ChainName: "D Chain Mainnet",
    BNB_ChainName: "BNB Smart Chain",
    ETH_ChainName: "Ethereum",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
} else {
  config = {
    frontendurl: "https://panel.cuptoken.trade",
    backendurl: "https://api.cuptoken.trade/",
    adminBackendUrl: "https://api.cuptoken.trade/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    bitcoin_explorer_url: 'https://btcscan.org',
    D_Chain_Rpc_Url: 'https://mainnetcoin.d-ecosystem.io/',
    BNB_Chain_Rpc_Url: "https://bsc-dataseed1.binance.org/",
    ETH_Chain_Rpc_Url: "https://eth-mainnet.public.blastapi.io",
    CUP_Token_Addr: "0xf1dDA922fB5aCe0736957f8Ea3cB126016fa6602",
    Clara_Token_addr: "0xD10168ad1b3E706be6628b995B53d44182609C58",
    admin_Address: "0xD90cf321c82C310Ac09fDF6cD03b4E57C84868D7",
    busdt_token: "0x55d398326f99059fF775485246999027B3197955",
    dusd_token: "0x6d61434e5E7BC47D5166bA5C0f5c211122f100C8",
    D_ChainID: "5272",
    BNB_ChainID: "56",
    ETH_ChainID: "1",
    D_ChainName: "D Chain Mainnet",
    BNB_ChainName: "BNB Smart Chain",
    ETH_ChainName: "Ethereum",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
}

export default config;



