import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../axiosService/apiCall";
import * as XLSX from "xlsx"

function UserData() {
    const [data, setData] = useState([]);

    const columns = [
        { name: "S.No", selector: (row) => row.sno },
        {
            name: "Email",
            width: "300px",
            selector: (row) => row.email,
            cell: (row) => (
                <div className="no-wrap-cell">{row.email}</div>
            )
        },
        // { name: "Date", width: "200px", selector: (row) => (<div className="date">{row.date}</div>) },
        // { name: "Time (UTC)", width: "150px", selector: (row) => (row.time) },
    ]

    // function formatDate() {
    //     const date = new Date();
    //     const formattedDate = date.toISOString().split('T')[0];
    //     return formattedDate;
    // }

    const datadetails =
        data && Array.isArray(data) &&
        data.map((row, index) => ({
            sno:  index + 1,
            email: row.email ? row.email : "-----",
            // date: formatDate(row.date),
            // time: row.time ? row.time : "-----", 
            _id: row._id,
        }));

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data.map((row, index) => ({
            "S.No": index + 1,
            Email: row.email ? row.email : "-----"
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "User Data");
        XLSX.writeFile(workbook, "user_data.xlsx");
    };

    

    const fetchData = async () => {
        try {
            let params = {
                url: `/getUserdatas`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            console.log("response--", response);
            if (response.status == true) {
                const result = response.userResult;
                console.log('result--', result);
                setData(result);
                
            } else {
                setData([]);
               
            }
        } catch (error) {
            console.error("An error occurred:", error.message);
        }
    };

    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            if (typeof selector(a) === "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0;
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0;
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);



    return (

        <div className="transaction_history custom-userdata">
            <>
                <ToastContainer />
                <div className="container-fluid" style={{ marginLeft: "10px" }}>
                    <nav className="navbar navbar-expand-lg py-3">
                        <div className="container p-0">
                            <h3 className=" component-user">UserData</h3>
                            <button onClick={downloadExcel} className="user-btn-spinner-1">Download Excel</button>
                        </div>
                    </nav>
                    <div className="search-container">

                    </div>
                    <div className=" d-flex custom-user-table">
                        <DataTable
                            columns={columns}
                            data={datadetails}
                            pagination
                            persistTableHead
                            sortFunction={customSort}
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                    </div>

                </div>
            </>

        </div>

    )
};

export default UserData