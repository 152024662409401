import React, { useEffect, useState, useRef } from "react";
import { makeApiRequest } from "../axiosService/apiCall";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import config from "./config";
import { IoMdClose } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";
import { FaCopy } from "react-icons/fa6";
import { id } from "ethers";
import Web3 from "web3";
import BUSDT_TokenABI from "../ABI/BUSDT.json";
import DUSD_TokenABI from "../ABI/DUSD.json";

function Withdrawstatus() {
  const itemsPerPage = 5;
  const [pendingTrans, setPendingTrans] = useState([]);
  const [completedTrans, setCompletedTrans] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [completeCurrentPage, setCompleteCurrentPage] = useState(1);
  const [completetotalPages, setCompleteTotalPages] = useState(1);
  const [selectedRow, setSelectedRow] = useState("")
  const [btcHash, setBtcHash] = useState("")
  const [coinBtc, setCoinBtc] = useState("")
  const [validationMessage, setValidationMessage] = useState("")
  const [sendAmnt, setSendAmnt] = useState("")
  const utcDate = new Date();
  const modalClose = useRef()
  const currTime = utcDate.toUTCString();

  var web3js = '';
  if (window.ethereum) {
    web3js = new Web3(window.ethereum);
  }

  const getWithdrawTransactions = async (page) => {
    try {
      let param = {
        url: `withdraw-transactions?page=${page}`,
        method: "GET",
      };
      let response = await makeApiRequest(param);
      if (response.status == true) {
        // console.log("response", response);
        setPendingTrans(response.pendingWithdraws);
        setTotalPages(response.totalPages);
      } else {
        setPendingTrans([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCompletedWithdrawTrans = async (pages) => {
    try {
      let param = {
        url: `withdraw-transactions?page=${pages}`,
        method: "GET",
      };
      let response = await makeApiRequest(param);
      if (response.status) {
        setCompletedTrans(response.completedWithdraws);
        setCompleteTotalPages(response.compWithdrawTotalPages);
      } else {
        setCompletedTrans([]);
        setCompleteTotalPages(1);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fixedBalance = (amt) => {
    let balanceAmt = Number(amt)
    // console.log("balanceAmt",balanceAmt);
    return balanceAmt.toFixed(8)
  }
  useEffect(() => {
    getWithdrawTransactions(currentPage);
  }, [currentPage]);
  useEffect(() => {
    getCompletedWithdrawTrans(completeCurrentPage);
  }, [completeCurrentPage]);

  useEffect(() => {
    setBtcHash("")
  }, [])


  const totalAmount = (recAmt, tnxFee) => {
    let userInput = Number(recAmt) + Number(tnxFee)
    let resultVal = fixedBalance(userInput);
    return resultVal
  }

  const formatDate = (dateString) => {
    const dateTime = new Date(dateString);

    const formattedDateTime = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    }).format(dateTime);

    const [month, day, yearTime] = formattedDateTime.split('/');
    const [year, time] = yearTime.split(',');

    const formattedDateTimeWithComma = `${year}-${month}-${day}, ${time}`;

    return formattedDateTimeWithComma;
  };
  const formatAddress = (address) => {
    const addr =
      address.substring(0, 5) + "..." + address.substring(address.length - 4);
    return addr;
  };

  const pendingTransDetails =
    pendingTrans &&
    pendingTrans.map((row, index) => ({
      sno: (currentPage - 1) * itemsPerPage + index + 1,
      email: row.email,
      coin: row.coin,
      ToAddress: row.toAddress,
      WithdrawAmt: totalAmount(row.amount, row.txFee),
      Amount: row.amount,
      TXFee: row.txFee,
      Date: row.createdAt,
      ID: row._id
    }));

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast("Address copied to clipboard!");
      })
      .catch((error) => {
        // console.error("Failed to copy:", error);
        toast("Failed to copy address to clipboard.");
      });
  }


  const pendingColumns = [
    { name: "S.No", width: "60px", selector: (row) => row.sno },

    {
      name: "Email",
      width: "300px",
      selector: (row) => row.email,
      cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
    },
    {
      name: "Coin",
      selector: (row) => row.coin,
      cell: (row) => <div className="no-wrap-cell">{row.coin}</div>,
    },
    {
      name: "TO Address",
      width: "200px",
      selector: (row) => row.ToAddress,
      cell: (row) => (
        <div className="no-wrap-cell">
          {formatAddress(row.ToAddress)}
          <span onClick={() => copyToClipboard(row.ToAddress)}><FaCopy className="ms-1" /></span>
        </div>
      ),
    },
    {
      name: "Withdraw Amt",
      selector: (row) => row.WithdrawAmt,
      width: "200px",
      cell: (row) => <div className=" no-wrap-cell">{row.WithdrawAmt}</div>,
    },
    {
      name: "Received Amt",
      width: "200px",
      selector: (row) => row.Amount,
      cell: (row) => <div className=" no-wrap-cell">{fixedBalance(row.Amount)}</div>,
    },
    {
      name: "TX Fee",
      width: "200px",
      selector: (row) => row.TXFee,
      cell: (row) => <div className=" no-wrap-cell">{fixedBalance(row.TXFee)}</div>,
    },
    {
      name: "Date (UTC)",
      width: "300px",
      selector: (row) => row.Date,
      cell: (row) => <div className="date">{formatDate(row.Date)}</div>,
    },
    {
      name: "Status",
      width: "200px",
      button: true,
      cell: (row) => (
        <div className="d-flex">
          {loader === true ? (
            <button type="button" className="register-btn-1 me-2 register-btn-copy">
              <div className="d-flex gap-2">
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </button>
          ) : row.coin === "BTC" ? (
            <button type="button" class="register-btn-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop1"
              onClick={() => { setSelectedRow(row.ID, row.coin); setCoinBtc(row.coin); setSendAmnt(fixedBalance(row.Amount)) }}
            >
              BTC PAY
            </button>
          ) : (
            <button type="button" class="register-btn-1 me-2 register-btn-copy" onClick={() => handleEdit(row.ID, row.coin, row.WithdrawAmt, row.Amount, row.TXFee, row.Date, row.ToAddress)}>
              Approve
            </button>
          )
          }
        </div>
      ),
    },

  ];

  const handleBTC = (e) => {
    const event = e.target.value;
    const regex = /^[0-9a-fA-F]{0,64}$/;
    if (regex.test(event)) {
      setBtcHash(event);
      setValidationMessage("");
    } else if (regex.test(event) === "") {
      setBtcHash("");
      setValidationMessage("");
    } else {
      setValidationMessage("Invalid BTC Transaction Hash");
    }
  };

  const withdrawSubmit = async (ID, coin, hashVal) => {
    setLoader(true)
    try {

      let params;
      if (coin == "BTC") {
        let payLoad = {
          transID: ID
        }

        params = {
          url: `approve-transaction?btcHash=${btcHash}`,
          method: "POST",
          data: payLoad
        };
      } else {
        let payLoad = {
          transID: ID,
          currTime: currTime,
          thash: hashVal,
        }

        params = {
          url: `approve-transaction`,
          method: "POST",
          data: payLoad
        };
      }
      const response = await makeApiRequest(params);
      // console.log("response---", response);
      if (response.status == true) {
        toast.success(response.message);
        getWithdrawTransactions();
        getCompletedWithdrawTrans();
        setLoader(false)
      } else {
        // console.log("else");
        toast.error(response.message);
        setBtcHash("")
        setLoader(false)
      }
    } catch (error) {
      // console.log(error);
      setLoader(false)
    }

  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const handleEdit = async (ID, coin, WithdrawAmt, Amount, TXFee, Date, ToAddr) => {

    try {
      let CHAIN_ID = "";
      let CHAIN_NAME = "";
      let RPC_URL = "";
      let resultOfTransfer = {};
      let hashVal = "";
      let swalResult = "";

      if (coin == "ETH") {
        CHAIN_ID = config.ETH_ChainID;
        CHAIN_NAME = config.ETH_ChainName;
        RPC_URL = config.ETH_Chain_Rpc_Url;
      } else if (coin == "BNB" || coin == "BSC-USD") {
        CHAIN_ID = config.BNB_ChainID;
        CHAIN_NAME = config.BNB_ChainName;
        RPC_URL = config.BNB_Chain_Rpc_Url;
      } else if (coin == "DUSD") {
        CHAIN_ID = config.D_ChainID;
        CHAIN_NAME = config.D_ChainName;
        RPC_URL = config.D_Chain_Rpc_Url;
      }
      if (coin == "BTC") {
        // console.log("coin---btc--", coin);

        resultOfTransfer = btcHash
        // console.log("function for BTC");
        if (resultOfTransfer) {
          withdrawSubmit(ID, coin, hashVal);
          if (modalClose.current) {
            // setBtcHash("")
            modalClose.current.click();
          }
        } else if (resultOfTransfer == "") {
          setValidationMessage("Enter BTC Transaction Hash");
          // toast.error("hash required..")
        } else {
          toast.error("Transaction Failed..")
        }

      } else {
        // console.log("coin-----", coin);
        swalResult = await Swal.fire({
          title: "Are you sure want to approve?",
          icon: "question",
          iconHtml: "?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          showCloseButton: true
        });

        if (swalResult.isConfirmed) {
          const ChainConfirmation = await Swal.fire({
            title: `Please Switch to ${coin == "DUSD" ? "D-Chain" : coin == "BSC-USD" ? "BNB" : coin} Network`,
            icon: "info",
            iconHtml: "!",
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            showCloseButton: true
          });
          if (ChainConfirmation.isConfirmed) {
            setLoader(true)
            if (window.ethereum) {
              const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
              });

              localStorage.setItem('address', accounts[0])
              const address = localStorage.getItem('address');
              // console.log("address===----", address);

              const network = await switchNetwork(CHAIN_ID, CHAIN_NAME, RPC_URL)

              let buyAmount = "";
              let gasPrice = "";

              if (network === "Switching error") {
                toast.error(`Failed to Switch the ${coin == "DUSD" ? "D-Chain" : coin == "BSC-USD" ? "BNB" : coin} Network..`)
                setLoader(false)
                return
              }

              if (coin == "BSC-USD") {
                // console.log("function for BSC-USD");
                const BSC_USD_Token_address = new web3js.eth.Contract(BUSDT_TokenABI, config.busdt_token);

                gasPrice = web3js.utils.toWei('20', 'gwei');
                buyAmount = await web3js.utils.toWei(Amount, 'ether');

                resultOfTransfer = await BSC_USD_Token_address.methods.transfer(ToAddr, buyAmount).send({
                  from: address,
                  gasPrice: gasPrice,
                });

                hashVal = resultOfTransfer.transactionHash
              } else if (coin == "DUSD") {
                // console.log("function for DUSD");
                const DUSD_Token_address = new web3js.eth.Contract(DUSD_TokenABI, config.dusd_token);

                gasPrice = web3js.utils.toWei('20', 'gwei');
                buyAmount = await web3js.utils.toWei(Amount, 'ether');

                resultOfTransfer = await DUSD_Token_address.methods.transfer(ToAddr, buyAmount).send({
                  from: address,
                  gasPrice: gasPrice,
                });

                hashVal = resultOfTransfer.transactionHash
              } else {
                // ETH & BNB
                buyAmount = await web3js.utils.toWei(Amount, 'ether');
                gasPrice = await web3js.eth.getGasPrice();
                // gasPrice = await web3js.utils.toWei("50","gwei");
                var trxaccounts = await web3js.eth.getTransactionCount(address, 'pending');
                // let nonceData = web3js.utils.toHex(trxaccounts);

                resultOfTransfer = await web3js.eth.sendTransaction({
                  to: ToAddr,
                  from: address,
                  value: buyAmount,
                  gas: 21000,
                  gasPrice: gasPrice,
                  // nonce: nonceData
                })

                hashVal = resultOfTransfer.transactionHash
              }
              // console.log("resultOfTransfer---",resultOfTransfer);

            } else {
              setLoader(false)
              toast.warn("Please install metamask");
              openInNewTab(`https://metamask.io/download/`);
              return
            }
            if (resultOfTransfer.status == 1n) {
              withdrawSubmit(ID, coin, hashVal)
            } else {
              setLoader(false)
              toast.error("Transaction Failed...")
            }
          } else {
            setLoader(false)
            toast.error("Failed...")
          }
        } else {
          setLoader(false)
          toast.error("Failed...")
        }
      }
      // console.log("resultOfTransfer---", resultOfTransfer);




    } catch (error) {
      // console.log(error);
      setLoader(false)
      toast.error("Transaction Failed");
    }
  };


  const switchNetwork = async (CHAINID, CHAINNAME, RPCURL) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(CHAINID).toString(16)}` }],
      });
      return "Switching successful";

    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: CHAINID,
                chainName: CHAINNAME,
                rpcUrls: [RPCURL],
              },
            ],
          });
          return "Chain added successfully";

        } catch (addError) {
          return "Error adding chain";
        }
      }
      return "Switching error";
    }
  };


  const completedTransDetails =
    completedTrans &&
    completedTrans.map((row, index) => ({
      sno: (completeCurrentPage - 1) * itemsPerPage + index + 1,
      email: row.email,
      coin: row.coin,
      ToAddress: row.toAddress,
      WithdrawAmt: totalAmount(row.amount, row.txFee),
      Amount: row.amount,
      TXFee: row.txFee,
      Date: row.createdAt,
      approveDate: row.updatedAt,
      TXHash: row.txhash
    }));

  const completedColumns = [
    { name: "S.No", width: "60px", selector: (row) => row.sno },

    {
      name: "Email",
      width: "300px",
      selector: (row) => row.email,
      cell: (row) => <div className="no-wrap-cell">{row.email}</div>,
    },
    {
      name: "Coin",
      selector: (row) => row.coin,
      cell: (row) => <div className="no-wrap-cell">{row.coin}</div>,
    },
    {
      name: "TO Address",
      width: "200px",
      selector: (row) => row.ToAddress,
      cell: (row) => <div className="no-wrap-cell">{formatAddress(row.ToAddress)}</div>,
    },
    {
      name: "Withdraw Amt",
      width: "200px",
      selector: (row) => row.WithdrawAmt,
      cell: (row) => <div className=" no-wrap-cell">{row.WithdrawAmt}</div>,
    },
    {
      name: "Received Amt",
      width: "200px",
      selector: (row) => row.Amount,
      cell: (row) => <div className=" no-wrap-cell">{fixedBalance(row.Amount)}</div>,
    },
    {
      name: "TX Fee",
      width: "200px",
      selector: (row) => row.TXFee,
      cell: (row) => <div className=" no-wrap-cell">{fixedBalance(row.TXFee)}</div>,
    },
    {
      name: "Requested Date (UTC)",
      width: "300px",
      selector: (row) => row.Date,
      cell: (row) => <div className="date">{formatDate(row.Date)}</div>,
    },
    {
      name: "Approve Date (UTC)",
      width: "300px",
      selector: (row) => row.approveDate,
      cell: (row) => <div className="date">{formatDate(row.approveDate)}</div>,
    },
    {
      name: "TX Hash",
      width: "200px",
      button: true,
      selector: (row) => row.TXHash,
      cell: (row) => {
        if (row.coin === "ETH" && row.TXHash) {
          return <a
            href={`${config.sepolia_explorer_url}/tx/${row.TXHash}`}
            target="_blank"
          >
            {formatAddress(row.TXHash)}
          </a>
          // return <div className="date">{formatAddress(row.TXHash)} e</div>
        } else if (row.coin === "BSC-USD" && row.TXHash) {
          return <a
            href={`${config.bnb_explorer_url}/tx/${row.TXHash}`}
            target="_blank"
            className="text-dark"
          >
            {formatAddress(row.TXHash)}
          </a>
        } else if (row.coin === "DUSD" && row.TXHash) {
          return <a
            href={`${config.dchain_explorer_url}/tx/${row.TXHash}`}
            target="_blank"
            className="table-download"
          >
            {formatAddress(row.TXHash)}
          </a>
        } else if (row.coin === "BNB" && row.TXHash) {
          return <a
            href={`${config.bnb_explorer_url}/tx/${row.TXHash}`}
            target="_blank"
            className="text-dark"
          >
            {formatAddress(row.TXHash)}
          </a>
        } else if (row.coin === "BTC" && row.TXHash) {
          return <a
            href={`${config.bitcoin_explorer_url}/tx/${row.TXHash}`}
            target="_blank"
            className="text-dark"
          >
            {formatAddress(row.TXHash)}
          </a>
        } else {
          return <div className="date">-</div>
        }
      }
    },
  ];

  const paginationOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: false,
  };



  return (
    <div className="App text-dark">
      <ToastContainer />
      <div>
        <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0 d-flex align-items-center pb-3 justify-content-center mt-2">
                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                <div className="top-modal-1">
                  <button
                    type="button"
                    class="close-btn-1"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={modalClose}
                  >
                    <IoMdClose />
                  </button>
                </div>
              </div>
              <div class="modal-body">
              
                <div className="text-center  custom-cup-body-1">
                  
                  <h2 style={{ background: "#bc9018", color: "white", borderRadius: "9px 9px 0px 0px" }} class="modal-title p-1" id="exampleModalLabel">BTC TXS</h2>

                  <div class="mb-3 mt-3 row d-flex justify-content-center">
                    <div class="col-sm-11 m-2">
                      <input type="text" class="form-control custom-input-1" placeholder="BTC Transaction Hash" id="inputPassword"
                        value={btcHash}
                        onChange={(e) => handleBTC(e)}
                      />
                      {validationMessage && <div style={{ color: 'red', textAlign: "left" }}>{validationMessage}</div>}
                    </div>
                    <h6 style={{ color: "#bc9018", display: "flex", marginLeft: "55px"}} class="modal-title p-1" id="exampleModalLabel">Send Amount: {sendAmnt} </h6>
                  </div>
                  
                  <div class="mb-3 justify-content-center border-0">
                    <button type="button" class="register-btn-4"
                      onClick={() => handleEdit(selectedRow, coinBtc)}
                      disabled={validationMessage !== ""} // Disable the button if validationMessage is not empty
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className='d-flex' style={{ margin: "0px 10px" }}>
                  <p className='p-0' style={{ width: "140px", textAlign: "end", color: "#bc9018" }}>Note :</p>
                  <div className='ms-0'>
                    {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                                                      <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                    <ul style={{ listStyleType: "none", textAlign: "justify", paddingLeft: "10px" }}>

                      <li>Make ensure the Correct Transaction Hash given. If you insert wrong Transaction Hash, process
                        will failed. Check the hash before insert.</li>
                    </ul>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="custom-withdrawstatus">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className=" py-3">
                  <h3 className="component-user">Withdraw Status</h3>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-12 p-0">
                    <div className="custom-inside-withdraw mt-3">
                      <ul
                        class="nav nav-pills mb-3 text-center justify-content-lg-end align-items-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li>

                          <button type="button" class="me-3 info-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            <CiCircleInfo />
                          </button>


                          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">0
                              <div class="modal-content">
                                <div class="modal-header border-0 py-0 m-2 d-flex align-items-center  justify-content-center ">
                                  <h1 class="modal-title fs-5" id="staticBackdropLabel" style={{ color: "#bc9018" }}>Remainder</h1>
                                  <button type="button" style={{ position: "absolute", right: "10px" }} class="close-btn-1" data-bs-dismiss="modal" aria-label="Close"> <IoMdClose /></button>
                                </div>
                                <div class="modal-body">
                                  <div className='submitSpans'>
                                    <div className='d-flex'>
                                      <p className='text-dark' style={{ width: "260px", textAlign: "end" }}>BNB coin</p>
                                      <div className='ms-0'>
                                        {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                                                      <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                        <ul style={{ listStyleType: "initial", textAlign: "justify" }}>
                                          <li> Make ensure ADMIN wallet should have minimum 0.1 BNB coin (Gas fee) for BSC-USD Withdraw Approval.</li>

                                        </ul>
                                      </div>

                                    </div>
                                    <div className='d-flex'>
                                      <p className='text-dark' style={{ width: "260px", textAlign: "end" }}>DCX coin</p>
                                      <div className='ms-0'>
                                        {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                                                      <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                        <ul style={{ listStyleType: "initial", textAlign: "justify" }}>

                                          <li> Make ensure ADMIN wallet should have minimum 0.1 DCX coin (Gas fee) for DUSD Withdraw Approval.</li>
                                        </ul>
                                      </div>

                                    </div>

                                    <div className='d-flex'>
                                      <p className='text-dark' style={{ width: "143px", textAlign: "end" }}>For Withdraw</p>
                                      <div className='ms-0'>
                                        {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                                                      <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                        <ul style={{ listStyleType: "initial", textAlign: "justify" }}>

                                          <li>Within 24Hrs you need Approve all Withdraw requests.</li>
                                        </ul>
                                      </div>

                                    </div>


                                  </div>
                                </div>
                                {/* <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                  <button type="button" class="btn btn-primary">Understood</button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active register-btn-2 me-3"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          // onClick={setCurrentPage(1)}
                          >
                            Pending
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link register-btn-2"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          // onClick={setCurrentPage(1)}
                          >
                            Completed
                          </button>
                        </li>

                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active ms-2"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          tabindex="0" SUBMIT

                        >

                          <DataTable
                            className="text-center "
                            columns={pendingColumns}
                            data={pendingTransDetails}
                            pagination
                            paginationServer
                            paginationComponentOptions={paginationOptions}
                            paginationTotalRows={totalPages * 5}
                            paginationPerPage={5}
                            onChangePage={(page) => {
                              setCurrentPage(page)
                              getWithdrawTransactions(page)
                            }
                            }
                            persistTableHead={true}
                          />

                        </div>
                        <div
                          class="tab-pane fade  ms-2"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                          tabindex="0"
                        >
                          <DataTable
                            className="text-center "
                            columns={completedColumns}
                            data={completedTransDetails}
                            pagination
                            paginationServer
                            paginationComponentOptions={paginationOptions}
                            paginationTotalRows={completetotalPages * 5}
                            paginationPerPage={5}
                            onChangePage={(page) => {
                              setCompleteCurrentPage(page)
                              getCompletedWithdrawTrans(page)
                            }
                            }
                            persistTableHead={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
}

export default Withdrawstatus;
