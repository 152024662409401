import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../src/pages/sidebar";
import Login from '../src/pages/login';
import ProtectedRoute from '../src/protected'
import ForgotPassword from "./pages/forgot";
import Error from "./pages/Error.js";
import Errorpage from "./pages/Errorpage.js";
import ResetPassword from "./pages/reset";
import { useEffect } from "react";
import { makeApiRequest } from "./axiosService/apiCall";
import Authenticator from "./pages/Authenticator.js";


function App() {
  useEffect(() => {
    let adminAuth = async () => {
      let adminToken = localStorage.getItem('token');
      // console.log("adminToken",adminToken);
      let params = {
        url: "admin-auth",
        method: "POST",
      }
      let response = await makeApiRequest(params);
      if (response.status) {
        // console.log("response API",response);
      } else {
        // localStorage.clear()  
      }
    }
    adminAuth();
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/logout" element={<Sidebar />} />

          <Route path="/userData" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />

          <Route path="/bulkOrder" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />
          <Route path="/fractionOrder" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />
          <Route path="/import" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />
          <Route path="/add-subadmin" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }

          />
          <Route path="/redeemed-order" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />
          <Route path="/kyc-status" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }

          />

          <Route path="/admin-earn" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }

          />

          <Route path="/error" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }

          />

          <Route path="/security" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />

          <Route path="/withdraw-status" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />

          <Route path="/Wallet-balance" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />

          <Route path="/site-setting" element={
            <ProtectedRoute>
              {" "}
              <Sidebar />{" "}
            </ProtectedRoute>
          }
          />

          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/forgotPass/:token" element={<ResetPassword />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="*" element={<Errorpage />} />
          <Route path="/dashboard" element={<ProtectedRoute><Sidebar /></ProtectedRoute>} />
          <Route exact path="/authenticator" element={<Authenticator />} />


          {/* <Route exact path="/fractionOrder" element={<Sidebar/>} /> */}
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;