import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { FaAsymmetrik, FaEdit } from "react-icons/fa";
import { Link, useAsyncError } from "react-router-dom";
import config from './config'
import truncateEthAddress from "truncate-eth-address";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../axiosService/apiCall";
import { Modal } from 'react-bootstrap'
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { IoMdClose } from "react-icons/io";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { FaStarOfLife } from "react-icons/fa";
import cuptokenFormat from '../assets/demo-excel/example-cuptoken-format.csv';
import { MdDownloadForOffline } from "react-icons/md";


const ImportFile = () => {
  const itemsPerPage = 5;
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [coin, setCoin] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [Price, setPrice] = useState('');
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [addAdminError, setAddAdminError] = useState("");
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState("password");
  const [currentfractioncost, setCurrentfractioncost] = useState();

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith(".csv")) {
        setFile(selectedFile);
      } else {
        toast.error("Please select a .csv file.");
      }
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const FindCurrentCost = async () => {
    try {
      let params = {
        url: `getFindCurrentCost`,
        method: "GET",
      };
  
      const response = await makeApiRequest(params);
      // console.log("FindCurrentCost----", response);
      if (response.status == true) {
        setCurrentfractioncost(response.findCurrentCost.currentfractioncost);
      } else {
        setCurrentfractioncost(0);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }


  function formatId(id) {
    const convertFromAddress = truncateEthAddress(id);
    return convertFromAddress;
  }

  const columns = [
    { name: "S.No", selector: (row) => row.sno },
    { name: "Ampoule No", selector: (row) => row.ampouleno },
    { name: "Net weight", selector: (row) => row.netweight },
    // { name: "My Holdings", selector: (row) => row.myholdings },
    { name: "Status", selector: (row) => row.status },
    { name: "Product Type", selector: (row) => row.productType },
    { name: "Rate of grams", selector: (row) => row.currentfractioncost },
    { name: "Bulk rate", selector: (row) => row.currentbulkcost },
  ];


  const datadetails =
    data &&
    data.map((row, index) => ({
      sno: (currentPage - 1) * itemsPerPage + index + 1,
      ampouleno: row.ampouleno,
      netweight: row.netweight,
      status: row.status === "Sell" || row.status === "InProcess" ? "Sold" : row.status === "Completed" ? "Burned" : "Active",
      productType: row.productType ? row.productType : "--",
      currentfractioncost: row.currentfractioncost,
      currentbulkcost: row.currentbulkcost,
      _id: row._id,
    }));

  const paginationOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: false,
  };

  const handleUpload = async () => {
    try {
      if (file) {
        setLoader(true)
        const formData = new FormData();
        formData.append("fileUpload", file);
        let params = {
          url: `upload`,
          method: "POST",
          data: formData,
          header: "image"
        };
        let response = await makeApiRequest(params);
        if (response.status == true) {
          toast.success("Upload Successfully");
          setLoader(false)
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          setLoader(false)
          toast.error(response.data.message);
        }
      } else {
        setLoader(false)
        toast.error("Please select a file before uploading.");
      }
    } catch (error) {
      setLoader(false)
      console.log("An error occurred:", error.message);
      toast.error("Something went wrong..!");

    }
  };

  const fetchData = async (page) => {
    try {
      const encodedSearchQuery = encodeURIComponent(searchQuery);
      let params = {
        url: `fileCSV?query=${encodedSearchQuery}&page=${page}`,
        method: "get"
      }
      const response = await makeApiRequest(params)
      if (response.status == true) {
        const result = response.data;
        setData(result);
        setTotalPages(response.totalPages);
      } else {
        setData([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  useEffect(() => {
    FindCurrentCost();
  }, []);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchQuery]);

  const handleValidation = async () => {
    const newErrors = {};
    if (!Price) {
      newErrors.Price = 'Price required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      // Show the modal using Bootstrap's modal methods
      const modal = new bootstrapBundleMin.Modal(document.getElementById('exampleModal'));
      modal.show();
    }
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target; 

    if (name === 'email') {
        setEmail(value); 
    } else if (name === 'password') {
        setPassword(value); 
    }

    const validationErrors = {};

    if (!value.trim()) {
        validationErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`; 
    } else if (name === 'email' && !isValidEmail(value)) {
        validationErrors[name] = "Invalid email format";
    } else {
        delete validationErrors[name]; 
    }

    if (!password) {
      validationErrors.password = "Password is required";
    }

    setAddAdminError(validationErrors); 
}

  const handleVerify = async () => {
    const validationErrors = {};
    try {
      if (!email) {
        validationErrors.email = "Email is required";
      } else if (!isValidEmail(email)) {
        validationErrors.email = "Invalid email format";
      }

      if (!password) {
        validationErrors.password = "Password is required";
      }
      if (Object.keys(validationErrors).length > 0) {
        setAddAdminError(validationErrors);
      } else {
        setLoader1(true)
        const payload = {
          Email: email,
          Password: password,
          Price,
        }

        let params = {
          url: `verifySiteSetting`,
          method: "POST",
          data: payload,
        }
        const response = await makeApiRequest(params)
        if (response.status == true) {
          setLoader1(false)
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        else {
          setLoader1(false)
          toast.error(response.message);
        }
      }

    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }
  return (
    <div className="transaction_history">
      <ToastContainer />
      <div className="container-fluid" style={{ marginLeft: "15px" }}>
        <nav className="navbar navbar-expand-lg py-3">
          <div className="container p-0">
            <h3 className=" " style={{ color: "#bc9018" }}>Upload Cuptoken</h3>
          </div>
        </nav>
        <div className="row">
          <div className="col-lg-12">
            <div className="row  justify-content-between align-items-center text-right">
              <div className="col-lg-7">
                <div className="file-button d-flex  py-lg-2 mb-lg-0 mb-4 ms-lg-0">
                  <input
                    type="file"
                    name="fileUpload"
                    // style={{ color: "#bc9018" }}
                    id="fileUpload"
                    accept=".csv"
                    onChange={handleFileChange}
                    className="custom-part-files-1"

                  />
                  {loader == true ? (
                    <a className="logout-button-dash"><Spinner animation="border" /></a>
                  ) : (
                    <button onClick={handleUpload} className="logout-button-dash">Upload
                    </button>
                  )}

                </div>
                <div style={{ color: "black", display: "flex" }}>
                  <p> <span style={{ color: "#bc9018" }}>Note : </span><span style={{ color: "red", fontSize: "10px", }}> <FaStarOfLife style={{ marginTop: "-3px" }} /></span> This field only accepts .csv and .xl files.</p>
                </div>
                <div style={{ color: "black", display: "flex" }}>
                  <p> <span style={{ color: "#bc9018" }}>Example cuptoken file format : </span><a style={{ color: "#bc9018" }} href={cuptokenFormat} download="cuptoken-format.csv" target="_blank">Click to download. <MdDownloadForOffline style={{fontSize:"20px"}} /></a></p>
                </div>
                <div style={{ color: "black", display: "flex" }}>
                  <p> <span style={{ color: "#bc9018" }}>Note : </span><span style={{ color: "red", fontSize: "10px", }}> <FaStarOfLife style={{ marginTop: "-3px" }} /></span> Upload cuptokens based on this format otherwise it refused.</p>
                </div>

                <div className="search-container ms-lg-0">
                  <input
                    type="text"
                    placeholder="Search by Ampoule No "
                    value={searchQuery}
                    className="input-design p-2"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <p className="" style={{ color: "#bc9018" }}>Current Gram Price :${currentfractioncost}</p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className=" ms-lg-4 custom-cup-input mb-3 mb-lg-3">
                  <h4 className="text-center">Ampoule Price</h4>
                  <div class=" row mt-3">

                    <label for="inputPrice" class="col-sm-5 col-form-label text-end" style={{ fontSize: "18px" }}>1 Gram Price</label>
                    <div class="col-sm-6">
                      <div class="col-sm-12">
                        <input type="text"
                          class="form-control custom-input-1 w-100"
                          id="inputGramPrice"
                          name="inputGramPrice"
                          onChange={(e) => setPrice(e.target.value)}
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            // Allow only numeric values (0-9), the backspace key (8), and a single dot (.)
                            if (!/^[0-9\b]+$/.test(keyValue) && (keyValue !== '.' || event.target.value.includes('.'))) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={handlePaste}
                        />
                      </div>
                      {errors.Price && <div className="invalid-email error password-text-33 variable-width">{errors.Price}</div>}
                    </div>

                    <div class=" mt-4 text-center">

                      {loader == true ? (
                        <a class="register-btn-4"><Spinner animation="border" /></a>
                      ) : (
                        <button class="register-btn-4 mt-3 mt-lg-0"
                          onClick={handleValidation}
                        >
                          Submit
                        </button>
                      )}

                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content custom-cup-modal-1">
                            <div class="modal-header  border-0 d-flex align-items-center pb-3 justify-content-center mt-2">

                              <div className="top-modal-1">
                                <button
                                  type="button"
                                  class="close-btn-1"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <IoMdClose />
                                </button>
                              </div>

                            </div>
                            <div class="modal-body">
                              <div className="text-center  custom-cup-body-1">
                                <h2 style={{ background: "#bc9018", color: "white" }} class="modal-title p-1" id="exampleModalLabel">Verify</h2>

                                <div class="mb-3 mt-3 row">

                                  <label for="inputPassword" class="col-sm-3 col-form-label text-lg-end">EMail</label>
                                  <div class="col-sm-8 ms-2">
                                    <input type="email" class="form-control custom-input-1" id="inputPassword" onChange={handleChange} name="email" value={email}/>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {addAdminError.email && <>{addAdminError.email}</>}
                                    </span>
                                  </div>
                                </div>
                                <div class="mb-3 row">
                                  <label for="inputPassword" class="col-sm-3 col-form-label text-lg-end"  >Password</label>
                                  <div class="col-sm-8 ms-2">
                                    <div className="input-group mb-0">
                                      <input type={type} style={{ borderRight: "0px" }} class="form-control custom-input-1" id="inputPassword" name="password" onChange={handleChange} value={password}/>
                                      <span class="input-group-text eye-icon-1">
                                        {/* <FaEyeSlash /> */}
                                        <Icon
                                          class="absolute mr-10"
                                          icon={icon}
                                          size={25}
                                          onClick={handleToggle}
                                        />
                                      </span>

                                    </div>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {addAdminError.password && (
                                        <>{addAdminError.password}</>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer justify-content-center border-0">
                              {loader1 == true ? (
                               <a  class="register-btn-spinner-1 pt-2"><Spinner animation="border" /></a>
                              ) : (
                                <button
                                  // type="button" 
                                  class="register-btn-spinner-1"
                                  onClick={handleVerify}
                                >Submit</button>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <br />
        <DataTable
          columns={columns}
          data={datadetails}
          pagination
          paginationServer
          paginationComponentOptions={paginationOptions}
          paginationTotalRows={totalPages * 5}
          paginationPerPage={5}
          onChangePage={(page) => {
            setCurrentPage(page)
            fetchData(page)
          }
          }
          persistTableHead={true}
        />
      </div>
    </div>
  );
};

export default ImportFile;