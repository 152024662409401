import React, { useEffect, useState } from "react";
import { makeApiRequest } from "../axiosService/apiCall";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios'
import "../../src/App.css";
import { Autocomplete } from "@mui/material";
import { useForm } from "react-hook-form";

function Sitesetting() {
    const [formValues, setFormValues] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [ipAddress, setIpAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [siteSettingData, setSiteSettingData] = useState({})
    const [withDrawLoading, setWithDrawLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: form2Errors },
        setValue: setValue2
    } = useForm();

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        formState: { errors: form3Errors },
        setValue: setValue3
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            let params = {
                url: "store-unique-data",
                method: "POST",
                data
            }
            let response = await makeApiRequest(params);
            if (response.status == true) {
                toast.success(response.message);
                setIsLoading(false);
                reset()
            } else {
                toast.error(response.message);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("Something went wrong");
        }
    };

    const onWithdrawSubmit = async (data) => {
        try {
            setWithDrawLoading(true)
            let params = {
                url: "withdraw-setting",
                method: "POST",
                data
            }
            let response = await makeApiRequest(params);
            // return false;
            if (response.status == true) {
                toast.success(response.message);
                setWithDrawLoading(false);
            } else {
                toast.error(response.message);
                setWithDrawLoading(false);
            }
        } catch (error) {
            setWithDrawLoading(false);
            toast.error("Something went wrong");
        }
    }

    const onDepositeSubmit = async (data) => {
        try {
            // console.log(data,"data deposite");
            setIsLoading(true);
            let params = {
                url: "deposite-setting",
                method: "POST",
                data
            }
            let response = await makeApiRequest(params);
            if (response.status == true) {
                toast.success(response.message);
                setIsLoading(false);
            } else {
                toast.error(response.message);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("Something went wrong");
        }
    }

    const getSiteSetting = async () => {
        try {
            let params = {
                url: "get-site-settings",
                method: "GET",
            }
            let res = await makeApiRequest(params)
            if (res.status == true) {
                setSiteSettingData(res.data)
            } else {
                setSiteSettingData({})
            }
        } catch (error) {
            setSiteSettingData({})
        }
    }

    useEffect(() => {
        getIPAddress();
        getSiteSetting();
    }, []);
    useEffect(() => {
        if (siteSettingData) {
            setValue3('btcMinBal', siteSettingData.BTC_MinBal);
            setValue3('ethMinBal', siteSettingData.ETH_MinBal);
            setValue3('busdtMinBal', siteSettingData.BUSDT_MinBal);
            setValue3('bnbMinBal', siteSettingData.BNB_MinBal);
            setValue3('dusdMinBal', siteSettingData.DUSD_MinBal);
            setValue3('BTC_Address', siteSettingData.BTC_DepositeAddress);
            setValue3('ETH_Address', siteSettingData.ETH_DepositeAddress);
            setValue3('BUSDT_Address', siteSettingData.BUSDT_DepositeAddress);
            setValue3('BNB_Address', siteSettingData.BNB_DepositeAddress);
            setValue3('DUSD_Address', siteSettingData.DUSD_DepositeAddress);
            setValue3('busdtCoin', siteSettingData.BNB_Coin_Limit)
            setValue3('dusdCoin', siteSettingData.DCX_Coin_Limit)

            setValue2('btcMinBal', siteSettingData.BTC_Withdraw_Amt)
            setValue2('ethMinBal', siteSettingData.ETH_Withdraw_Amt)
            setValue2('busdtMinBal', siteSettingData.BUSDT_Withdraw_Amt)
            setValue2('bnbMinBal', siteSettingData.BNB_Withdraw_Amt)
            setValue2('dusdMinBal', siteSettingData.DUSD_Withdraw_Amt)

        }
    }, [siteSettingData])

    const keyPre = () => {
        // document.getElementById('inputETHMinBal inputBTCMinBal inputMATICMinBal inputBNBMinBal inputDCXMinBal').addEventListener('input', function(e) {
        //     const value = e.target.value;
        //     const dots = value.split('.').length - 1;
        //     if (dots > 1) {
        //         e.target.value = value.slice(0, value.length - 1);
        //     }
        // });
        document
            .querySelectorAll(
                "#inputETHMinBal, #inputBTCMinBal, #inputBUSDTMinBal, #inputBNBMinBal, #inputDUSDMinBal"
            )
            .forEach(function (element) {
                element.addEventListener("input", function (e) {
                    const value = e.target.value;
                    const dots = value.split(".").length - 1;
                    if (dots > 1) {
                        e.target.value = value.slice(0, value.length - 1);
                    }
                });
            });
    };
    const tabClick = (tab) => {
        if (tab == 1) {
            setFormErrors({});
        } else {
            setFormErrors({});
        }
    };

    const getIPAddress = async () => {
        try {
            let ipResponse = await axios.get("https://api.ipify.org/?format=json")
            if (ipResponse.status == 200) {
                setIpAddress(ipResponse.data.ip)
            } else {
                setIpAddress('')
            }
        } catch (error) {
            console.log("IP ERROR");
        }
    }
    const validateWithdrawAmount = async (inputAmt) => {
        if (Number(inputAmt) === 0) {
            return "Please enter a value other than 0";
        }
        return true;
    }


    return (
        <div className='App text-dark'>
            <ToastContainer />
            <div className='container-fluid' style={{ marginLeft: "15px" }}>
                <div className='custom-site-setting mt-3'>
                    <div className=''>

                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className="custom-tab-site mt-5">
                                            <ul class="nav nav-pills mb-4  text-center justify-content-lg-end align-items-center" id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active register-btn-2 me-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Deposit Setting</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link  register-btn-2" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Withdraw Editing</button>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                    <div className="mt-0">
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div className='row justify-content-center '>
                                                                    <div className='col-lg-10'>
                                                                        <h2>Site Settings</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-lg-10">
                                                                <div className="site-admin-add-1">
                                                                    <form key={3} onSubmit={handleSubmit3(onDepositeSubmit)}>
                                                                        <div className='mb-1'>
                                                                            <div className="">
                                                                                <h3>Move To Admin</h3> <br/>
                                                                                <h5 >BTC</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Min Balance :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                id="inputBTCMinBal"
                                                                                                name="BTCMinBal"
                                                                                                {...register3("btcMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.BTC_MinBal ? siteSettingData.BTC_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.btcMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.btcMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Address :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input type="text" class="form-control custom-input-1"
                                                                                                name="BTC_Address"
                                                                                                {...register3("BTC_Address", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    minLength: {
                                                                                                        value: 10,
                                                                                                        message: "Minimum 10 characters above"
                                                                                                    }
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.BTC_DepositeAddress ? siteSettingData.BTC_DepositeAddress : ""}
                                                                                            />
                                                                                            {form3Errors.BTC_Address && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.BTC_Address.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="">
                                                                                <h5 >ETH</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Min Balance :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                id="inputETHMinBal"
                                                                                                name="ethMinBal"
                                                                                                {...register3("ethMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.ETH_MinBal ? siteSettingData.ETH_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.ethMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.ethMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Address :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input type="text" class="form-control custom-input-1" name="ETH_Address"
                                                                                                {...register3("ETH_Address", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    minLength: {
                                                                                                        value: 20,
                                                                                                        message: "Minimum 20 characters above"
                                                                                                    }
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.ETH_DepositeAddress ? siteSettingData.ETH_DepositeAddress : ""}
                                                                                            />
                                                                                            {form3Errors.ETH_Address && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.ETH_Address.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <h5 >BSC-USD</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Token Limit :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                name="busdtMinBal"
                                                                                                {...register3("busdtMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.MATIC_MinBal ? siteSettingData.MATIC_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.busdtMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.busdtMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Coin Transfer Limit :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                name="busdtCoin"
                                                                                                {...register3("busdtCoin", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.MATIC_MinBal ? siteSettingData.MATIC_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.busdtCoin && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.busdtCoin.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Address :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input type="text" class="form-control custom-input-1" name="BUSDT_Address"
                                                                                                {...register3("BUSDT_Address", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    minLength: {
                                                                                                        value: 20,
                                                                                                        message: "Minimum 20 characters above"
                                                                                                    }
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.MATIC_DepositeAddress ? siteSettingData.MATIC_DepositeAddress : ""}
                                                                                            />
                                                                                            {form3Errors.BUSDT_Address && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.BUSDT_Address.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <h5>BNB</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Min Balance :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                name="bnbMinBal"
                                                                                                {...register3("bnbMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.BNB_MinBal ? siteSettingData.BNB_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.bnbMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.bnbMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Address :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input type="text" class="form-control custom-input-1" name="BNB_Address"
                                                                                                {...register3("BNB_Address", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    minLength: {
                                                                                                        value: 20,
                                                                                                        message: "Minimum 20 characters above"
                                                                                                    }
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.BNB_DepositeAddress ? siteSettingData.BNB_DepositeAddress : ""}
                                                                                            />
                                                                                            {form3Errors.BNB_Address && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.BNB_Address.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <h5>DUSD</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Token Limit :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                name="dusdMinBal"
                                                                                                {...register3("dusdMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.DCX_MinBal ? siteSettingData.DCX_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.dusdMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.dusdMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Coin Transfer Limit :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control custom-input-1"
                                                                                                name="dusdCoin"
                                                                                                {...register3("dusdCoin", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.DCX_MinBal ? siteSettingData.DCX_MinBal : ""}
                                                                                            />
                                                                                            {form3Errors.dusdCoin && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.dusdCoin.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-3 col-form-label text-end">Address :</label>
                                                                                        <div class="col-sm-9">
                                                                                            <input type="text" class="form-control custom-input-1" name="DUSD_Address"
                                                                                                {...register3("DUSD_Address", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    minLength: {
                                                                                                        value: 20,
                                                                                                        message: "Minimum 20 characters above"
                                                                                                    }
                                                                                                })}
                                                                                            // defaultValue={siteSettingData.DCX_DepositeAddress ? siteSettingData.DCX_DepositeAddress : ""}
                                                                                            />
                                                                                            {form3Errors.DUSD_Address && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form3Errors.DUSD_Address.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            {isLoading == true ? (
                                                                                <button className="register-btn-spinner-1" >
                                                                                    <Spinner animation="border" />
                                                                                </button>
                                                                            ) : (
                                                                                <button type="submit" className="register-btn-spinner-1">Submit</button>
                                                                            )}
                                                                        </div>
                                                                    </form>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                                    <div className="mt-0">
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div className='row justify-content-center '>
                                                                    <div className='col-lg-10'>
                                                                        <h2>Site Settings</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-lg-10">
                                                                <div className="site-admin-add-1">
                                                                    <div className='mb-1'>
                                                                        <form key={1} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                                                            <div className="">
                                                                                <h5>Withdraw Editing</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">BTC Withdraw Address :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" id="inputPassword" {...register("BTC_Withdraw_Address", {
                                                                                                required: true,
                                                                                                minLength: 15
                                                                                            })} />
                                                                                            {errors.BTC_Withdraw_Address && errors.BTC_Withdraw_Address.type === "required" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    BTC Address is required
                                                                                                </div>
                                                                                            )}
                                                                                            {errors.BTC_Withdraw_Address && errors.BTC_Withdraw_Address.type === "minLength" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    Enter the valid Address otherwise couldn't approve withdraw
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">BTC PrivateKey :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" id="inputPassword" {...register("BTC_PrivateKey", {
                                                                                                required: true,
                                                                                                minLength: 20
                                                                                            })} />
                                                                                            {errors.BTC_PrivateKey && errors.BTC_PrivateKey.type === "required" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    BTC Privatekey is required
                                                                                                </div>
                                                                                            )}
                                                                                            {errors.BTC_PrivateKey && errors.BTC_PrivateKey.type === "minLength" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    Enter the valid private key otherwise couldn't approve withdraw
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">EVM Withdraw Address :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" id="inputPassword" {...register("EVM_Withdraw_Address", {
                                                                                                required: true,
                                                                                                minLength: 20
                                                                                            })} />
                                                                                            {errors.EVM_Withdraw_Address && errors.EVM_Withdraw_Address.type === "required" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    EVM Address is required
                                                                                                </div>
                                                                                            )}
                                                                                            {errors.EVM_Withdraw_Address && errors.EVM_Withdraw_Address.type === "minLength" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    Enter the valid address otherwise couldn't approve withdraw
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">EVM PrivateKey :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" id="inputPassword" {...register("EVM_PrivateKey", {
                                                                                                required: true,
                                                                                                minLength: 20
                                                                                            })} />
                                                                                            {errors.EVM_PrivateKey && errors.EVM_PrivateKey.type === "required" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    EVM Privatekey is required
                                                                                                </div>
                                                                                            )}
                                                                                            {errors.EVM_PrivateKey && errors.EVM_PrivateKey.type === "minLength" && (
                                                                                                // <p className="errorMsg">Email is required.</p>
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    Enter the valid private key otherwise couldn't approve withdraw
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-center">
                                                                                    {isLoading == true ? (
                                                                                        <button className="register-btn-spinner-1" >
                                                                                            <Spinner animation="border" />
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button type="submit" className="register-btn-spinner-1">Submit</button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </form>

                                                                        <form key={2} onSubmit={handleSubmit2(onWithdrawSubmit)}>
                                                                            <div className="">
                                                                                <h5>Minimum Withdraw Amount</h5>
                                                                                <div className='mt-3'>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">BTC :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" name="btcMinBal"
                                                                                                {...register2("btcMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            />
                                                                                            {form2Errors.btcMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form2Errors.btcMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">ETH :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" name="ethMinBal"
                                                                                                {...register2("ethMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            />
                                                                                            {form2Errors.ethMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form2Errors.ethMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">BSC-USD :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" name="busdtMinBal"
                                                                                                {...register2("busdtMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            />
                                                                                            {form2Errors.busdtMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form2Errors.busdtMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">BNB :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" name="bnbMinBal"
                                                                                                {...register2("bnbMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            />
                                                                                            {form2Errors.bnbMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form2Errors.bnbMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="mb-3 row">
                                                                                        <label for="inputPassword" class="col-sm-4 col-form-label text-end">DUSD :</label>
                                                                                        <div class="col-sm-8">
                                                                                            <input type="text" class="form-control custom-input-1" name="bnbMinBal"
                                                                                                {...register2("dusdMinBal", {
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: 'This field is required'
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                                                                                        message: 'Please enter a valid number(123) or decimal(0.10)',
                                                                                                    },
                                                                                                    maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Maximum 20 characters allowed"
                                                                                                    },
                                                                                                    validate: validateWithdrawAmount
                                                                                                })}
                                                                                            />
                                                                                            {form2Errors.dusdMinBal && (
                                                                                                <div className="invalid-email error password-text-33 variable-width">
                                                                                                    {form2Errors.dusdMinBal.message}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                {withDrawLoading == true ? (
                                                                                    <button className="register-btn-spinner-1" >
                                                                                        <Spinner animation="border" />
                                                                                    </button>
                                                                                ) : (
                                                                                    <button type="submit" className="register-btn-spinner-1">Submit</button>
                                                                                )}
                                                                            </div>
                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sitesetting