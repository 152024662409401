import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "./config";
import { makeApiRequest } from "../axiosService/apiCall";
import { ToastContainer, toast } from "react-toastify";
import "../../src/App.css";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { IoMdClose } from "react-icons/io";

function Kycstatus() {
  const [kycStatus, setKycStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const itemsPerPage = 5;


  const kycVerifyStatus = async () => {
    try {
      let params = {
        url: `kycVerifyStatus`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      if (response.status == true) {
        const kycStatus = response.data;
        setKycStatus(kycStatus);
      } else {
        setKycStatus([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewImage = (imageUrl) => {
    // console.log(imageUrl);
    setSelectedImage(imageUrl);
  };

  const rejectKYC = async (id) => {
    try {
      const swalResult = await Swal.fire({
        title: "Are you sure you want to Reject KYC?",
        icon: "question",
        iconHtml: "?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true,
        html:
          '<label for="swal-input" style="margin-top: 10px;">Enter reason for rejection</label>' +
          '<input id="swal-input" class="swal2-input input-reason" placeholder="Reason" maxlength="255">',
        preConfirm: () => {
          const rejectionReason = document.getElementById('swal-input').value;

          if (rejectionReason.length > 255) {
            Swal.showValidationMessage('Reason cannot exceed 255 characters');
            return false;
          }

          if (!rejectionReason.trim()) {
            Swal.showValidationMessage('Please provide a reason for rejection');
            return false;
          }
          return rejectionReason;
        },
      });

      if (swalResult.isConfirmed) {

        const rejection = swalResult.value;

        let payload = {
          id: id,
          reason: rejection,
        };
        let params = {
          url: `kycRejectStatus`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);
        if (response.status == true) {
          toast.success(response.message);
          kycVerifyStatus();
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleKYC = async (id) => {
    try {
      const swalResult = await Swal.fire({
        title: "Are you sure you want to Approve KYC?",
        icon: "question",
        iconHtml: "?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        showCloseButton: true
      });

      if (swalResult.isConfirmed) {
        let payload = {
          id: id,
        };
        let params = {
          url: `kycConformStatus`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);
        if (response.status == true) {
          toast.success(response.message);
          kycVerifyStatus();
        }
      }

    } catch (error) {
      console.log(error);
    }
  }




  useEffect(() => {
    kycVerifyStatus();

    //set Time Interval
    const interval = setInterval(() => {
      kycVerifyStatus();
    }, 60000);

    return () => clearInterval(interval);
  }, [currentPage]);


  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const totalPages = Math.ceil(kycStatus.length / itemsPerPage);
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      const leftBoundary = Math.min(
        Math.max(1, currentPage - Math.floor(maxVisiblePages / 2)),
        totalPages - maxVisiblePages + 1
      );
      const rightBoundary = Math.max(
        Math.min(totalPages, currentPage + Math.floor(maxVisiblePages / 2)),
        maxVisiblePages
      );

      if (currentPage < Math.floor(maxVisiblePages / 2) + 1) {
        for (let i = 1; i <= maxVisiblePages; i++) {
          paginationNumbers.push(i);
        }
      } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
        for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        for (let i = leftBoundary; i <= rightBoundary; i++) {
          paginationNumbers.push(i);
        }
      }
    }

    return paginationNumbers.map((pageNumber, index) => (
      <li
        key={index}
        className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => setCurrentPage(pageNumber)}
        >
          {pageNumber}
        </button>
      </li>
    ));
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid " style={{ marginLeft: "15px" }}>
        <div className="custom-saled-order text-dark">
          <div className="row">
            <div className="col-sm-12 ">

              <div className="py-3 justify-content-center d-flex justify-content-between">
                <h3 className="component-user  text-center ms-lg-0">KYC Status</h3>
              </div>
              <div className="custom-inside-saled-order text-center mt-5">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="custom-datascroll">
                      <table className="table w-100 mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>KYC Document</th>
                            <th>KYC Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(kycStatus || []).length > 0 ? (
                            kycStatus
                              .slice(
                                (currentPage - 1) * itemsPerPage,
                                currentPage * itemsPerPage
                              )
                              .map((data, index) => (
                                <tr key={data._id}>
                                  <td>{index + 1 + ((currentPage - 1) * itemsPerPage)}</td>
                                  <td>{data.contactname}</td>
                                  <td>{data.email}</td>
                                  <td>
                                    <button
                                      className="register-btn-5"
                                      data-bs-toggle="modal"
                                      data-bs-target="#kycModal"
                                      onClick={() => handleViewImage(data.kycImage)}
                                    >
                                      View Image
                                    </button>
                                  </td>
                                  <td>
                                    {data.kycVerifyStatus === 1 || data.kycVerifyStatus === 2 ? (
                                      data.kycVerifyStatus === 2 ? (
                                        <button
                                          onClick={() =>
                                            handleKYC(data._id)
                                          }
                                          // className="register-btn-5"
                                          className={`register-btn-5 ${data.kycVerifyStatus === 2
                                            ? "fade-disabled"
                                            : ""
                                            }`}
                                          disabled={true} // Disable the button
                                        >
                                          KYC Verified
                                        </button>
                                      ) : (
                                        <div className="d-flex justify-content-center">
                                          <button
                                            onClick={() =>
                                              handleKYC(data._id)
                                            }
                                            className="register-btn-5"
                                          >
                                            Verify KYC
                                          </button>
                                          <button
                                            onClick={() =>
                                              rejectKYC(data._id,)
                                            }
                                            className="ms-3 register-btn-5">Reject</button>
                                        </div>


                                      )
                                    ) : null}
                                  </td>

                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {renderPaginationNumbers()}
                      </ul>
                    </nav>
                    <div
                      class="modal fade custom-modal-update-profil1"
                      id="kycModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog custom-modal-update-profile modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header d-flex align-items-center pb-0 justify-content-center">
                            <div>
                              <h3 className="text-center mb-0" style={{ color: "#BC9018" }}>UPLOADED IMAGE</h3>
                            </div>
                            <div className="top-modal-1">
                              <button
                                type="button"
                                class=" close-btn-1"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ><IoMdClose /></button>
                            </div>

                          </div>
                          <div class="modal-body">
                            <div>
                              {selectedImage ? (
                                <img src={selectedImage} style={{ maxWidth: '100%', height: 'auto' }} />
                              ) : (
                                <p>No Image</p>
                              )}

                            </div>
                          </div>
                          <div class="modal-footer border-0">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Kycstatus;
