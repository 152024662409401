import React, { useEffect, useState } from 'react'
import { IoMdRefresh } from "react-icons/io";
import { makeApiRequest } from '../axiosService/apiCall';
import wallet1 from "../assets/Image/wallet1.png"
import dusd_png from "../assets/Image/dusd.png"

function Walletbalance() {
    const [walletBalance,setWalletBalance] = useState({})

    const getWalletBalances = async() =>{
        try {
            let params = {
                url : "get-withdraw-address-balance",
                method : "POST"
            }
            let response = await makeApiRequest(params);
            // console.log("response",response);
            if(response.status == true){
                setWalletBalance(response.data)
            }else{
                setWalletBalance({})
            }
        } catch (error) {
            console.log("getWalletBalances error",error);
        }
    }

    useEffect(()=>{
        // console.log(walletBalance.ETH_Balance,"walletBalance.ETH_Balanc");
        getWalletBalances();
    },[])

    const fixedBalance = (amt) => {
        let balanceAmt = Number(amt);
        return balanceAmt.toFixed(5);
    };
    
    return (
        <div className='App text-dark'>
            <div className='container-fluid'>
                <div className='customer-wallet-balance' style={{ marginLeft: "25px",marginTop:"50px" }}>
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-12 p-0'>

                                <div className='row justify-content-center'>
                                    <div className='col-lg-9'>
                                        <div className=" py-0">
                                            <h3 className="component-user">Wallet Balance</h3>
                                        </div>
                                        <div className='customer-inside-wallet-balance'>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src={wallet1}></img> BTC Address : {walletBalance.BTC_Address == undefined || walletBalance.BTC_Address == "" ? "-" : walletBalance.BTC_Address}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src="https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"></img> BTC Balances : {walletBalance.BTC_Balance == undefined || walletBalance.BTC_Balance == 0 ? "0.00000" : fixedBalance(walletBalance.BTC_Balance)}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src={wallet1}></img>  EVM Address : {walletBalance.EVM_Address == undefined || walletBalance.EVM_Address == "" ? "-" : walletBalance.EVM_Address}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src="https://app.1inch.io/assets/images/network-logos/ethereum.svg"></img> ETH Balances : {walletBalance.ETH_Balance == undefined || walletBalance.ETH_Balance == 0 ? "0.00000" : fixedBalance(walletBalance.ETH_Balance)}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src="https://bscscan.com/token/images/busdt_32.png"></img> BSC-USD Balances : {walletBalance.BUSDT_Balance == undefined || walletBalance.BUSDT_Balance == 0 ? "0.00000" : fixedBalance(walletBalance.BUSDT_Balance)}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src="https://cryptologos.cc/logos/bnb-bnb-logo.png"></img> BNB Balances : {walletBalance.BNB_Balance == undefined || walletBalance.BNB_Balance == 0 ? "0.00000" : fixedBalance(walletBalance.BNB_Balance)}</p>
                                            <p style={{fontSize:"18px"}}><img style={{width:"30px"}} src={dusd_png}></img> DUSD Balances : {walletBalance.DUSD_Balance == undefined || walletBalance.DUSD_Balance == 0 ? "0.00000" : fixedBalance(walletBalance.DUSD_Balance)}</p>
                                            <div className='text-center'>
                                                <button className='register-btn-spinner-1' onClick={getWalletBalances}><IoMdRefresh /> Refresh</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Walletbalance
