import React from 'react'
import { Link } from 'react-router-dom';

function Error() {
    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-error'>
                    <div className='row text-center'>
                        <div className='col-lg-12'>
                            <div class="section">
                                <h1 class="error">404</h1>
                                <div class="page">Ooops!!! The page you are looking for is not found</div>
                                {/* <a class="back-home" href="#!">Back to home</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error