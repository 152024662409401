import React, { useEffect, useState } from "react";
import "../css/transaction.css";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../axiosService/apiCall";
import moment from "moment"

const ImportFile = () => {
  const itemsPerPage = 5;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    { name: "S.No", selector: (row) => row.sno },
    { name: "Ampoule.No",width: "150px", selector: (row) => row.ampouleno },
    { name: "Contact FirstName", width: "150px", selector: (row) => row.contactname },
    { name: "Contact Lastname", width: "150px", selector: (row) => row.contactLastname },
    { name: "Company Name", width: "150px", selector: (row) => row.companyname },
    {
      name: "Address", width: "300px",  selector: (row) => row.address,
      cell: (row) => <div className="word-wrap-cell" style={{display:"table"}}>{row.address}</div>
    },
    { name: "Country Name", width: "150px", selector: (row) => row.countryname },
    { name: "PinCode", width: "150px", selector: (row) => row.pincode },
    { name: "Mobile Number", width: "150px", selector: (row) => row.mobilenumber },
    {
      name: "Email",
      width: "300px",
      selector: (row) => row.email,
      cell: (row) => (
        <div className="no-wrap-cell">{row.email}</div>
      )
    },
    { name: "Quantity", selector: (row) => row.quantity },
    { name: "Date", width: "150px", selector: (row) => (<div className="date">{row.date}</div>) },
    { name: "Time (UTC)", width: "150px", selector: (row) => moment.utc(row.time).format("hh:mm A") },
  ];
  function formatDate(dbdate) {
    const date = new Date(dbdate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }

  const address = (address) => {
    if (address.substring().length > 5) {
      let returnData = address.substring(0, 20) + "..." + address.substring(address.length - 10)
      return returnData
    }
  }



  const datadetails =
    data &&
    data.map((row, index) => ({
      sno: (currentPage - 1) * itemsPerPage + index + 1,
      ampouleno: row.soldItem.ampouleno,
      contactname: row.contactname,
      contactLastname: row.contactLastname,
      companyname: row.companyname ? row.companyname : "--",
      address: address(row.address) || row.address,
      countryname: row.countryname,
      pincode: row.pincode,
      mobilenumber: row.mobilenumber,
      email: row.email,
      quantity:row.quantity,
      date: formatDate(row.date),
      time: row.time,
      _id: row._id,
    }));

  const paginationOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: false,
  };

  const fetchData = async (page) => {
    try {
      const encodedSearchQuery = encodeURIComponent(searchQuery);
      let params = {
        url: `fileBuy?query=${encodedSearchQuery}&page=${page}`,
        method: "GET",
      }
      const response = await makeApiRequest(params);
      if (response.status == true) {
        const result = response.data;
        setData(result);
        setTotalPages(response.totalPages);
        setTotalItems(response.totalData);
      } else {
        setData([]);
        setTotalPages(1);
        setTotalItems(0);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };


  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchQuery]);
  return (

    <div className="transaction_history">
      <ToastContainer />
      <div className="container-fluid" style={{ marginLeft: "15px" }}>
        <nav className="navbar navbar-expand-lg py-3">
          <div className="container p-0">
            <h3 className=" component-user">Fraction Order History</h3>
          </div>
        </nav>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by ContactName / CompanyName "
            value={searchQuery}
            className="input-design component-box-input p-2"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <DataTable
          columns={columns}
          data={datadetails}
          pagination
          paginationServer
          paginationComponentOptions={paginationOptions}
          paginationTotalRows={totalItems}
          paginationPerPage={5}
          onChangePage={(page) => {
            setCurrentPage(page)
            fetchData(page)
          }
          }
          persistTableHead={true}
        />
      </div>
    </div>
  );
};

export default ImportFile;