import { useEffect, useState } from "react";
import { makeApiRequest } from "../axiosService/apiCall";
import "../css/dashboard.css"
import Chart from "./Chart";
import $ from "jquery";
function Dashboard() {

  const [orderType, setOrderType] = useState("Buy");
  const [selledBuyOrder, setSelledBuyOrder] = useState(0);
  const [completeddBuyOrder, setCompleteddBuyOrder] = useState(0);
  const [selledBulkOrder, setSelledBulkOrder] = useState(0)
  const [completedBulkOrder, setCompletedBulkOrder] = useState(0);

  const getAllAssest = async () => {
    let params = {
      url: "getOrderAllData",
      method: "GET",
    }
    const data = await makeApiRequest(params);
    // console.log("data---",data);
    if (data.status == true) {
      if (orderType === "Buy") {
        setSelledBuyOrder(data.selledBuyOrder);
        setCompleteddBuyOrder(data.CompleteddBuyOrder);
      } else {
        setSelledBulkOrder(data.selledBulkOrder);
        setCompletedBulkOrder(data.completedBulkOrder);
      }
    }else{
      setSelledBuyOrder(0);
      setCompleteddBuyOrder(0);
      setSelledBulkOrder(0);
      setCompletedBulkOrder(0);
    }
  }
  useEffect(() => {
    getAllAssest()
  }, [orderType]);

  const setType = (type) => {
    setOrderType(type)
  }

  $(document).ready(function () {
    $('.dashboard-btn-1').on('click', function () {
      // Remove 'activations' class from all buttons
      $('.dashboard-btn-1').removeClass('activations');

      // Add 'activations' class to the clicked button
      $(this).addClass('activations');
    });
  });
  return (
    <div className="mx-3 my-5">
      <div className="container">
        <div className="mt-3 mb-3 text-lg-end text-center">
          <button
            class="dashboard-btn-1 activations me-2 mb-lg-0 mb-3"
            onClick={() => setType("Buy")}
          >
            Fraction
          </button>

          <button
            class="dashboard-btn-1"
            onClick={() => setType("Bulk")}
          >
            Bulk
          </button>

        </div>
       
        <div className="row justify-content-center align-items-center ">
          <h3 className=" component-user text-center">
            {orderType == "Buy" ? "Fraction Orders" : "Bulk Orders"}
          </h3>

          <div class="col-sm-4 grid-margin  mb-3 mb-lg-0 mt-5">
            <div class="card">
              <div class="card-body">
                <h5>Sold</h5>
                <div class="row">
                  <div class="col-8 col-sm-12 col-xl-8 my-auto">
                    <div class="d-flex d-sm-block d-md-flex align-items-center">
                      <h2 class="mb-0 text-danger">{orderType == "Buy" ? selledBuyOrder : orderType == "Bulk" ? selledBulkOrder : 0}</h2>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                    <i class="icon-lg mdi mdi-wallet-travel text-danger ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 grid-margin  mb-3 mb-lg-0 mt-5">
            <div class="card">
              <div class="card-body">
                <h5>Burned</h5>
                <div class="row">
                  <div class="col-8 col-sm-12 col-xl-8 my-auto">
                    <div class="d-flex d-sm-block d-md-flex align-items-center">
                      <h2 class="mb-0 text-success">{orderType == "Buy" ? completeddBuyOrder : orderType == "Bulk" ? completedBulkOrder : 0}</h2>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                    <i class="icon-lg mdi mdi-monitor text-success ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
export default Dashboard;