import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import truncateEthAddress from "truncate-eth-address";
import { ToastContainer, toast } from "react-toastify";
import config from './config'
import { makeApiRequest } from "../axiosService/apiCall";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment"
import "../css/transaction.css";


const ImportFile = () => {
  const itemsPerPage = 5;
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [coin, setCoin] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  // const [loader, setLoader] = useState(false)


  const columns = [
    { name: "S.No", selector: (row) => row.sno },
    { name: "Ampoule.No",width: "150px", selector: (row) => row.ampouleno },
    { name: "Contact FirstName", width: "150px", selector: (row) => row.contactname },
    { name: "Contact LastName", width: "150px", selector: (row) => row.contactLastname },
    { name: "Company Name", width: "150px", selector: (row) => row.companyname },
    // { name: "Address", width: "150px",  style: { "word-wrap": "break-word"}, selector: (row) => row.address },
    {
      name: "Address", width: "200px", selector: (row) => row.address,
      cell: (row) => <div className="word-wrap-cell">{row.address}</div>
    },
    { name: "Country Name", width: "250px", selector: (row) => row.countryname },
    { name: "Pin Code", width: "100px", selector: (row) => row.pincode },
    { name: "Mobile Number", width: "150px", selector: (row) => row.mobilenumber },
    // { name: "Email", width: "150px", selector: (row) => row.email },
    {
      name: "Email",
      width: "300px",
      selector: (row) => row.email,
      cell: (row) => (
        <div className="no-wrap-cell">{row.email}</div>
      )
    },
    { name: "Date", width: "200px", selector: (row) => (<div className="date">{row.date}</div>) },
    { name: "Time (UTC)", width: "150px", selector: (row) => moment.utc(row.time).format("hh:mm A") },
  ];

  function formatDate(dbdate) {
    const date = new Date(dbdate);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  }

  const address = (address) => {
    // console.log(address.substring().length);
    if(address.substring().length > 5){
      let returnData = address.substring(0, 20) + "..." + address.substring(address.length - 10)
      return returnData
    }
  }
  

  const datadetails =
    data &&
    data.map((row, index) => ({
      sno: (currentPage - 1) * itemsPerPage + index + 1,
      ampouleno:row.soldItem.ampouleno,
      contactname: row.contactname,
      contactLastname: row.contactLastname,
      companyname: row.companyname ?  row.companyname : "--",
      address: address(row.address) || row.address,
      countryname: row.countryname,
      pincode: row.pincode,
      mobilenumber: row.mobilenumber,
      email: row.email,
      date: formatDate(row.date),
      time: row.time,
      _id: row._id,
    }));

  const paginationOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
    selectAllRowsItemText: false,
  };

  const fetchData = async (page) => {
    try {
      const encodedSearchQuery = encodeURIComponent(searchQuery);
      let params = {
        url: `fileBulk?query=${encodedSearchQuery}&page=${page}`,
        method: "GET",
      }
      const response = await makeApiRequest(params);
      // console.log("fileBulk---",response);
      if (response.status == true) {
        const result = response.data;
        const dataLength = response.data.length;
        setData(result);
        setTotalPages(response.totalPages);
        setTotalItems(response.totalData);
      } else {
        setData([]);
        setTotalPages(1);
        setTotalItems(0);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  // console.log(data,'setttdata');

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage,searchQuery]);

  return (
    <div className="transaction_history">
      <>
        <ToastContainer />
        <div className="container-fluid" style={{ marginLeft: "15px" }}>
          <nav className="navbar navbar-expand-lg py-3">
            <div className="container p-0">
              <h3 className=" component-user">Bulk Order History</h3>
            </div>
          </nav>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by ContactName / CompanyName "
              value={searchQuery}
              className="input-design component-box-input p-2"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={datadetails}
            pagination
            paginationServer
            paginationComponentOptions={paginationOptions}
            paginationTotalRows={totalItems}
            paginationPerPage={5}
            onChangePage={(page) => {
              setCurrentPage(page)
              fetchData(page)
            }
            }
            persistTableHead={true}
          />
        </div>
      </>

    </div>

  )
};

export default ImportFile;
